import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  List,
  ListItemText,
  TextField,
} from '@mui/material';
import { db } from '../../firebase.js';
import { getDatabase, ref, onValue } from 'firebase/database';
import { realtimeDb } from '../../firebase';
import Blocks from '../../stories/layout-components/Block.jsx';
import GeneralText from '../../stories/general-components/GeneralText.jsx';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase.js';
import { ListItem } from 'quill-delta-to-html';

const DashboardNode = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [calls, setCalls] = useState();
  const [nodeData, setNodeData] = useState();
  const [lastRefresh, setLastRefresh] = useState(new Date());

  const formattedDate = moment().add(5, 'hours').format('YYYY-MM-DD-HH');
  const formattedDateDisplay = moment().format('DD MMM HH');
  const formattedDateEndDisplay = moment().add(1, 'hours').format('HH');

  useEffect(() => {
    const metricsRef = ref(realtimeDb, `metrics/${formattedDate}`);

    const unsubscribe = onValue(
      metricsRef,
      (snapshot) => {
        const data = snapshot.val();

        if (data) {
          const sumOfCalls = Object.values(data);
          const routes = sumOfCalls
            ?.map((route) => {
              return (
                Object.values(route)?.reduce((acc, val) => {
                  return acc + val;
                }, 0) || 0
              );
            })
            ?.reduce((acc, val) => {
              return acc + val;
            }, 0);

          setCalls(routes);
        } else {
          setCalls(0);
        }
      },
      (error) => {
        console.error('Error reading Realtime Database:', error);
      }
    );

    return () => unsubscribe();
  }, [formattedDate, lastRefresh]);

  const getNodeDashboard = async () => {
    try {
      const pageData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/node-dashboard`,
        errorToast: t('errorLoadingDocument'),
        body: {},
      });
      setNodeData(pageData);
    } catch (error) {
      console.error('Error getting node dashboard:', error);
    }
  };

  useEffect(() => {
    getNodeDashboard();
    const interval = setInterval(() => {
      getNodeDashboard();
      setLastRefresh(new Date());
    }, 900000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="p-3 row">
      <div className="col-2">
        <Blocks noScroll heightPercentage={20} height={5} border>
          <div
            className="middle-content align-c"
            style={{
              padding: '25px',
            }}
          >
            <div className="mt-1">
              <GeneralText
                text={calls}
                fontSize="48px"
                size="bold"
                primary={true}
              />
            </div>

            <div className="">
              <GeneralText
                text={t('calls')}
                fontSize="12px"
                size="medium"
                primary={true}
              />
            </div>
            <div className="align-c d-flex middle-content ">
              <Chip
                label={
                  formattedDateDisplay + '-' + formattedDateEndDisplay + 'h'
                }
                size="small"
                style={{
                  backgroundColor: '#000000',
                  color: '#fff',
                  marginLeft: '5px',
                  height: '18px',
                  '& .MuiChipLabel': {
                    fontSize: '7px',
                  },
                }}
              ></Chip>
            </div>
          </div>
        </Blocks>
      </div>
      <div className="col-2">
        <Blocks noScroll heightPercentage={20} height={5} border>
          <div
            className="middle-content align-c"
            style={{
              padding: '25px',
            }}
          >
            <div className="mt-1">
              <GeneralText
                text={nodeData?.businesses?.length || 0}
                fontSize="48px"
                size="bold"
                primary={true}
              />
            </div>

            <div className="mt-2">
              <GeneralText
                text={t('accounts')}
                fontSize="16px"
                size="medium"
                primary={true}
              />
            </div>
          </div>
        </Blocks>
      </div>
      <div className="col-3">
        <Blocks noScroll heightPercentage={20} height={5} border>
          <div
            className="middle-content align-c"
            style={{
              padding: '25px',
            }}
          >
            <div className="mt-1">
              <GeneralText
                text={(Number(nodeData?.sales / 10000) || 0)?.toFixed(2) + ' $'}
                fontSize="42px"
                size="bold"
                primary={true}
              />
            </div>

            <div className="mt-3">
              <GeneralText
                text={t('sales')}
                fontSize="16px"
                size="medium"
                primary={true}
              />
            </div>
          </div>
        </Blocks>
      </div>
      <div className="col-3">
        <Blocks noScroll heightPercentage={20} height={5} border>
          <div
            className="middle-content align-c"
            style={{
              padding: '25px',
            }}
          >
            <div className="mt-3">
              <GeneralText
                text={t('redList')}
                fontSize="16px"
                size="medium"
                primary={true}
              />
            </div>
            <div>
              <List>
                {nodeData?.redDots?.map((dot, index) => {
                  return (
                    <ListItem divider>
                      <ListItemText primary={dot?.name} />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </div>
        </Blocks>
      </div>
    </div>
  );
};

export default DashboardNode;
