import React from 'react';

const FlashIcon = ({
  fill1 = '#b04848',
  fill2 = '#ff4848',
  fillNone = 'none',
  ...props
}) => (
  <svg
    id="Calque_2"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 90.9 90"
    {...props}
  >
    <defs>
      <style>
        {`
          .st0 { fill: ${fillNone}; stroke:none;}
          .st1 { fill: ${fill1};stroke:none; }
          .st2 { fill: ${fill2};stroke:none; }
        `}
      </style>
    </defs>
    <g id="Calque_1-2">
      <g>
        <path
          className="st0"
          d="M1.4,73.6v-8.4c-.9,2.1-1.4,4.4-1.4,6.9,0,9.9,8.1,18,18,18s2.9-.2,4.3-.5h-5c-8.8,0-15.9-7.1-15.9-15.9Z"
        />
        <path
          className="st2"
          d="M41.4,70.7c.4,7.5-2.6,14.3-7.7,18.9h41.2c8.8,0,15.9-7.1,15.9-15.9v-37l-42.2,10c-4,0-7.2-3.2-7.2-7.2L56.5,0H17.3C8.5,0,1.4,7.1,1.4,15.9v39.5c4.7-4.7,11.3-7.4,18.5-6.8,11.6.9,20.9,10.4,21.5,22h0Z"
        />
        <path
          className="st2"
          d="M35.9,72.1c0-9.9-8-18-18-18s-13.9,4.6-16.6,11.1v8.4c0,8.8,7.1,15.9,15.9,15.9h5c7.8-1.9,13.7-9,13.7-17.4Z"
        />
        <path
          className="st1"
          d="M48.7,46.6l42.2-9v.2V15.9C90.9,7.1,83.7,0,75,0h-23.5,1l-11,39.4c0,4,3.2,7.2,7.2,7.2h0Z"
        />
        <path
          className="st1"
          d="M18,54.1c9.9,0,18,8,18,18s-5.8,15.5-13.7,17.4h11.5c4.8-4.3,7.7-10.5,7.7-17.4,0-13-10.5-23.5-23.5-23.5s-12.3,2.6-16.6,6.9v9.8c2.7-6.5,9.1-11.1,16.6-11.1h0Z"
        />
      </g>
    </g>
  </svg>
);

export default FlashIcon;
