import React from 'react';

const NodeAI = ({
  strokeColor = '#fff',
  primaryColor = '#0000bf',
  secondaryColor = '#00007f',
}) => {
  const gradientColors = [
    { offset: '0.28', color: primaryColor || '#ff4848', opacity: '1' },
    { offset: '1', color: secondaryColor || '#200ef0', opacity: '1' },
  ];

  return (
    <svg
      id="Calque_AI"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 34.16 32.27"
      width="100%"
      height="100%"
    >
      <defs>
        <linearGradient
          id="custom-gradient"
          x1="69.72"
          y1="56.12"
          x2="70.14"
          y2="55.71"
          gradientTransform="translate(-5580.54 4491.85) scale(80 -80)"
          gradientUnits="userSpaceOnUse"
        >
          {gradientColors.map((stop, index) => (
            <stop
              key={index}
              offset={stop.offset}
              stopColor={stop.color}
              stopOpacity={stop.opacity}
            />
          ))}
        </linearGradient>
        <style>
          {`
            .cls-1 {
              fill: none;
              stroke: ${strokeColor};
              stroke-width: 4px;
            }
          `}
        </style>
      </defs>
      <g id="Calque_1-AI">
        <path
          className="cls-2"
          d="M15.44.68L.68,15.44c-.57.57-.81,1.41-.61,2.2l3.22,12.87c.39,1.55,2.2,2.25,3.53,1.36l8.98-5.99c.78-.52,1.79-.52,2.57,0l8.98,5.99c1.33.89,3.14.19,3.53-1.36l3.22-12.87c.2-.79-.03-1.62-.61-2.2L18.72.68c-.9-.9-2.37-.9-3.27,0Z"
          fill="url(#custom-gradient)"
        />
        <path
          className="cls-1"
          d="M8.42,7.71l8.66,8.66,8.66-8.66M17.08,16.37v9.13"
        />
      </g>
    </svg>
  );
};

export default NodeAI;
