import axios from 'axios';
import { auth } from '../firebase';
import { toast } from 'react-toastify';
import { createBrowserHistory } from 'history';

export const BASEURL =
  'https://northamerica-northeast1-node-canada.cloudfunctions.net/';

function redirectSigninFirebase() {
  const history = createBrowserHistory();
  auth.signOut();
  localStorage.removeItem('businessToken');
  history.push('/signin');
}

const nodeAxiosFirebase = async ({
  method = 'POST',
  url,
  body,
  noAuth = false,
  errorToast = false,
  showLoading = false,
  headers,
  options,
  t,
}) => {
  let toastId = null;
  const currentPath = window.location.pathname;

  try {
    let idToken = null;
    let response = null;

    // Show loading toast if enabled
    if (showLoading) {
      toastId = toast.loading(t('loading'));
    }

    if (!noAuth) {
      const user = auth.currentUser;
      if (user) {
        idToken = await user.getIdToken();
      }
    }

    const config = {
      method,
      url: url?.startsWith('http') ? url : `${BASEURL}${url}`,
      data: body,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST,OPTIONS',
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
        businesstoken: sessionStorage.getItem('businessToken'),
        ...headers,
      },
      ...options,
    };

    response = await axios(config);

    if (showLoading && toastId !== null) {
      toast.update(toastId, {
        render: t('success'),
        type: 'success',
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
    }

    return response.data;
  } catch (error) {
    // Dismiss loading toast
    if (showLoading && toastId !== null) {
      toast.dismiss(toastId);
    }

    if (errorToast) {
      toast.error(t('errorOccurred'));
    }

    if (currentPath?.startsWith('/app')) {
      if (error?.response?.data?.intCode?.endsWith('GEUA003')) {
        redirectSigninFirebase();
      } else if (error?.response?.data?.intCode?.endsWith('IP9')) {
        toast.error(t('ipRestrictionError'));
        setTimeout(() => {
          redirectSigninFirebase();
        }, 3000);
      }
    }

    return { errorData: error?.response?.data || error };
  }
};

export default nodeAxiosFirebase;
