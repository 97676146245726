// Libraries
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';
import { ListItem } from '@mui/material';
import {
  EditNoteOutlined,
  PreviewOutlined,
  ForwardToInboxOutlined,
  CheckCircleOutlineOutlined,
  MessageOutlined,
  EngineeringOutlined,
  PhoneAndroidOutlined,
  TaskAlt,
  ChatBubbleOutlineOutlined,
  ChatBubbleOutline,
} from '@mui/icons-material';

// Components
import GeneralText from '../../stories/general-components/GeneralText';
import Chip from '../../stories/general-components/Chip';
const HistoryMessage = ({
  text,
  timestamp,
  userId,
  type,
  description,
  name,
  badge,
  others,
}) => {
  const { t, i18n } = useTranslation();
  const currentLangCode = i18n.language;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const currentUser = useSelector((state) => state.core.user);
  const businessPreference = useSelector((state) => state.core.businessData);

  const secColor = businessPreference?.secColor || '#000000';
  const lightGreyScale = chroma
    .scale([secColor, '#ffffff'])
    .mode('lab')
    .colors(1)
    .map((color) => chroma(color).brighten(1.5));

  const getIconOfLog = (type) => {
    switch (type) {
      case 'cards:confirmed' || 'card:action:confirmed':
        return {
          icon: (
            <CheckCircleOutlineOutlined
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('statusConfimed'),
          status: 99,
          type: t('action'),
        };
      case 'cards:email' || 'card:email':
        return {
          icon: (
            <ForwardToInboxOutlined
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('emailSent'),
          status: 99,
          type: t('action'),
        };

      case 'card:view':
      case 'cards:view':
        return {
          icon: (
            <PreviewOutlined
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('cardOpened'),
          status: 1,
          type: t('public'),
        };
      case 'grids:email':
        return {
          icon: (
            <ForwardToInboxOutlined
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('emailSent'),
          status: 99,
          type: t('action'),
        };
      case 'delivered:email':
        return {
          icon: (
            <ForwardToInboxOutlined
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('emailDelivered'),
          status: 6,
          type: t('email'),
        };
      case 'grids:view':
        return {
          icon: (
            <PreviewOutlined
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('gridOpened'),
          type: t('public'),
          status: 1,
        };
      case 'cards:message':
        return {
          icon: (
            <MessageOutlined
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('message'),
          status: 0,
          type: t('manual'),
        };
      case 'cards:messagePublic':
        return {
          status: '',
          icon: (
            <MessageOutlined
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
          status: 1,
          label: t('messageContact'),
          type: t('public'),
        };
      case 'opened:email':
        return {
          icon: (
            <MessageOutlined
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
          status: 6,
          label: t('openedEmail'),
          type: t('email'),
        };
      case 'clicked:email':
        return {
          icon: (
            <MessageOutlined
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
          status: 6,
          label: t('clickedEmail'),
          type: t('email'),
        };
      case 'profile:maintenance':
        return {
          icon: (
            <EngineeringOutlined
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: '',
          status: 0,
          type: t('manual'),
        };
      case 'action:call':
        return {
          icon: (
            <PhoneAndroidOutlined
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('call'),
          status: 99,
          type: t('action'),
        };
      case 'nodies:created':
        return {
          icon: (
            <TaskAlt
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
          status: 99,
          label: t('newTask'),
          type: t('action'),
        };
      case 'note':
        return {
          icon: (
            <ChatBubbleOutline
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
          label: t('note'),
          type: t('manual'),
          status: 0,
        };

      default:
        return {
          label: 'Note',
          status: 0,
          type: t('manual'),
          icon: (
            <ChatBubbleOutline
              sx={{ marginTop: '1px', marginRight: '4px' }}
              fontSize="10px"
              htmlColor={businessPreference?.secColor}
            />
          ),
        };
    }
  };

  return (
    <ListItem sx={{ paddingLeft: 0, paddingRight: 0 }}>
      <div
        style={{
          backgroundColor:
            userId === currentUser?.uid
              ? lightGreyScale
              : isDarkMode
              ? '#1a1a1a'
              : '#fff',
          borderRadius:
            userId === currentUser?.uid
              ? '14px 14px 0px 14px'
              : '14px 14px 14px 0px',
          padding: '10px',
          color:
            userId === currentUser?.uid
              ? 'white !important'
              : 'black !important',
        }}
        className={`row mb-1 mt-1`}
      >
        <div className={'align-left col-9 d-flex '}>
          <div className="col-1">{getIconOfLog(type).icon}</div>
          <div className="col-10">
            <GeneralText
              text={name}
              fontSize="12px"
              size="bold"
              primary={false}
              color={businessPreference?.secColor}
            />
          </div>
        </div>
        <div className={'align-right col-3'}>
          {timestamp && (
            <GeneralText
              text={timestamp}
              fontSize="8px"
              size="regular"
              primary={true}
            />
          )}
        </div>
        <div style={{ marginTop: '-5px' }} className="col-12">
          <div>
            <GeneralText
              text={text || getIconOfLog(type).label}
              fontSize="12px"
              size="regular"
              primary={true}
              markdown
            />
          </div>
        </div>
      </div>
    </ListItem>
  );
};

export default HistoryMessage;
