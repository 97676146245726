import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
import { db } from '../../firebase';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TextComponent from './emailComponents/TextComponent';
import {
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
  getDoc,
  collection,
  serverTimestamp,
} from 'firebase/firestore';
import {
  fetchBusinessData,
  setGeneralStatus,
} from '../../redux/actions-v2/coreAction';
import { IconButton, Tooltip } from '@mui/material';
import {
  ArrowDropDown,
  ArrowDropUp,
  CodeOutlined,
  DeleteForeverOutlined,
  DeleteOutlined,
  InsertPhotoOutlined,
  SettingsOutlined,
  SmartButtonOutlined,
  SplitscreenOutlined,
  TextFieldsOutlined,
  TonalityOutlined,
} from '@mui/icons-material';
import Button from '../../stories/general-components/Button';
import ImageComponent from './emailComponents/ImageComponent';
import ButtonComponent from './emailComponents/ButtonComponent';
import Select from '../../stories/general-components/Select';
import TextField from '../../stories/general-components/TextField';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

const COMPONENTS = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  SPACE: 'SPACE',
  BUTTON: 'BUTTON',
  HTML: 'HTML',
  LOGO: 'LOGO',
};

// Draggable Item Component
const DraggableItem = ({ type, children }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    item: { type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'grab',
        padding: '8px',
        borderRadius: '6px',
        border: '1px dashed #ccc',
        margin: '4px',
      }}
    >
      {children}
    </div>
  );
};

// Droppable Area Component
const DroppableArea = ({ children, onDrop }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: [
      COMPONENTS.TEXT,
      COMPONENTS.IMAGE,
      COMPONENTS.SPACE,
      COMPONENTS.BUTTON,
      COMPONENTS.HTML,
      COMPONENTS.LOGO,
    ],
    drop: (item) => onDrop(item),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <div
      ref={drop}
      style={{
        width: '100%',
        border: '1px dashed #ccc',
        padding: '8px',
        borderRadius: '5px',
        minHeight: '50px',
        backgroundColor: isOver ? 'lightblue' : 'transparent',
        margin: '2px',
        transition: 'background-color 0.3s ease',
      }}
    >
      {children}
    </div>
  );
};

const BuilderEmail = ({ displaySettings }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { structureId, moduleName, elementId } = useParams();

  const isDarkMode = theme.palette.mode === 'dark';
  const currentLangCode = i18n.language;
  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const [columns, setColumns] = useState([]);
  const [selectedSettings, setSelectedSettings] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [tags, setTags] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const businessStructures = businessStructure?.structures;

  const currentStructure = businessStructures?.find(
    (structure) => structure.id === structureId
  );

  const handleChanges = (value, field) => {
    setSelectedTemplate((prevTemplate) => ({
      ...prevTemplate,
      [field]: value,
    }));
  };

  const addTemplate = async () => {
    try {
      const collectionRef = collection(
        db,
        'businessesOnNode',
        businessPreference.id,
        'emails'
      );

      const businessRef = doc(db, 'businessesOnNode', businessPreference.id);

      const newTemplate = {
        html: '',
        css: '',
        columns: [],
        name: t('new'),
        isDefault: false,
        subject: '',
        structureId: structureId,
        lang: currentLangCode,
        type: moduleName || '',
        businessId: businessRef,
      };

      const newDoc = await addDoc(collectionRef, newTemplate);

      //get the new template + id
      const newTemplateDoc = await getDoc(newDoc);
      setSelectedTemplate({
        id: newDoc.id,
        ...newTemplateDoc.data(),
      });
      dispatch(fetchBusinessData(businessPreference?.id, t));
    } catch (error) {
      console.error('Error adding template', error);
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };

  const handleDeleteTemplate = async () => {
    try {
      const docRef = doc(
        db,
        'businessesOnNode',
        businessPreference.id,
        'emails',
        selectedTemplate?.id
      );

      await deleteDoc(docRef);
      toast.success(t('deleted'));
      dispatch(fetchBusinessData(businessPreference.id, t));
      setSelectedTemplate(null);
    } catch (error) {
      console.error('Error deleting template', error);
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };

  const handleSave = async (html, css, columns) => {
    try {
      const docRef = doc(
        db,
        'businessesOnNode',
        businessPreference.id,
        'emails',
        selectedTemplate?.id
      );

      await updateDoc(docRef, {
        name: selectedTemplate?.name,
        body: selectedTemplate?.body || null,
        css: css || null,
        columns: columns,
        html: html,
        subject: selectedTemplate?.subject,
        isProject: selectedTemplate?.isProject || false,
        isInvoiced: selectedTemplate?.isInvoiced || false,
        lang: selectedTemplate?.lang,
        lastUpdate: serverTimestamp(),
        type: selectedTemplate?.type || 'cards',
        structureId: structureId,
      });

      toast.success(t('saved'));
      dispatch(fetchBusinessData(businessPreference.id, t));
      displaySettings();
      setIsLoaded(false);
    } catch (error) {
      console.error('Error updating template', error);
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };

  const handleChangeTemplate = (value) => {
    setSelectedTemplate(null);
    setIsLoaded(true);
    if (value === 'ID') {
      addTemplate();
    } else {
      const templateData = businessPreference?.emails?.find(
        (template) => template.id === value
      );
      setSelectedTemplate(templateData);
    }
  };

  const defaultTags = [
    {
      category: 'Personalization',
      entries: [
        {
          label: t('documentId'),
          value: '${data.cardId}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'nodies',
            'tasks',
            'passes',
            'contacts',
            'storages',
            'profiles',
            'cards',
            'grids',
          ],
        },
        {
          label: t('status'),
          value: '${data.status}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'cards',
            'nodies',
            'tasks',
            'grids',
            'passes',
          ],
        },
        {
          label: t('total'),
          value: '${data.finances.total}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'cards',
          ],
        },
        {
          label: t('subtotal'),
          value: '${data.finances.subtotal}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'cards',
          ],
        },
        {
          label: t('balance'),
          value: '${data.finances.balance}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'cards',
          ],
        },

        {
          label: t('assignedToId'),
          value: '${data.assignedToDetails.name}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'cards',
            'passes',
          ],
        },
        {
          label: t('shortCardId'),
          value: '${data.searchId}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'nodies',
            'tasks',
            'passes',
            'contacts',
            'storages',
            'profiles',
            'cards',
            'grids',
          ],
        },
        {
          label: t('targetDate'),
          value: '${data.targetDate}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'nodies',
            'tasks',
          ],
        },
        {
          label: t('invoiceDate'),
          value: '${data.invoiceDate}',
          hint: '',
          hidden: false,
          collections: ['cardsinvoiced'],
        },

        {
          label: t('attribute1'),
          value: '${data.attribute1}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'nodies',
            'tasks',
            'passes',
            'contacts',
            'storages',
            'profiles',
            'cards',
            'grids',
          ],
        },
        {
          label: t('attribute2'),
          value: '${data.attribute2}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'nodies',
            'tasks',
            'passes',
            'contacts',
            'storages',
            'profiles',
            'cards',
            'grids',
          ],
        },
        {
          label: t('attribute3'),
          value: '${data.attribute3}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'nodies',
            'tasks',
            'passes',
            'contacts',
            'storages',
            'profiles',
            'cards',
            'grids',
          ],
        },
        {
          label: t('attribute4'),
          value: '${data.attribute4}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'nodies',
            'tasks',
            'passes',
            'contacts',
            'storages',
            'profiles',
            'cards',
            'grids',
          ],
        },
        {
          label: t('name'),
          value: '${data.name}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'nodies',
            'tasks',
            'passes',
            'contacts',
            'storages',
            'profiles',
            'cards',
            'grids',
          ],
        },
        {
          label: t('targetName'),
          value: '${data.targetDetails.name}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'nodies',
            'tasks',
            'passes',
            'contacts',
            'storages',
            'profiles',
            'cards',
            'grids',
          ],
        },
        {
          label: t('businessName'),
          value: '${data.ownerDetails.name}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'nodies',
            'tasks',
            'passes',
            'contacts',
            'storages',
            'profiles',
            'cards',
            'grids',
          ],
        },
        {
          label: t('customBody'),
          value: '${data.customBody}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'nodies',
            'tasks',
            'passes',
            'contacts',
            'storages',
            'profiles',
            'cards',
            'grids',
          ],
        },
        {
          label: t('actionUrl'),
          value: '${data.actionUrl}',
          hint: '',
          hidden: false,
          collections: [
            'cardsinvoiced',
            'cardsuninvoiced',
            'cardsexpense',
            'nodies',
            'passes',
            'contacts',
            'grids',
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    if (selectedTemplate) {
      const formattedStructureTags =
        currentStructure?.fields
          ?.filter((field) => field?.type !== 'default')
          ?.map((field) => ({
            label: field?.name,
            value: `\${data.data.${field.value}}`,
            hint: field.description || '',
            hidden: false,
          })) || [];

      const mergedTags = defaultTags?.map((tag) => ({
        ...tag,
        entries: [...tag.entries, ...formattedStructureTags],
      }));

      setTags(mergedTags[0]?.entries);
    }
    setTimeout(() => {
      setIsLoaded(true);
    }, 1000);
  }, [selectedTemplate, currentStructure?.collectionField]);

  const handleDataColsChange = (col, comp, layoutIndex, value) => {
    if (value.ops) {
      value = JSON.stringify(value);
      if (value) {
        value = JSON.parse(value);
      }
    }

    setColumns((prevColumns) =>
      prevColumns.map((column) => {
        if (col === column.id) {
          return {
            ...column,
            layout: column?.layout.map((layout, layoutIdx) => {
              if (layoutIdx === layoutIndex) {
                return {
                  ...layout,
                  components: layout?.components.map((component, compIdx) => {
                    if (compIdx === comp) {
                      return {
                        ...component,
                        data: { ...(component.data || {}), content: value },
                      };
                    }
                    return component;
                  }),
                };
              }
              return layout;
            }),
          };
        }
        return column;
      })
    );
  };

  useEffect(() => {
    if (selectedTemplate) {
      setColumns(
        selectedTemplate.columns?.map((col) => ({
          ...col,
          settings: col.settings || { padding: '8px', backgroundColor: '#fff' },
        })) || []
      );
    }
  }, [selectedTemplate?.id]);

  // Add Columns
  const addColumn = (count) => {
    setColumns([
      ...columns,
      {
        id: Math.random().toString(36).substr(2, 9),
        layout: Array.from({ length: count }, () => ({
          id: Math.random().toString(36).substr(2, 9),
          components: [],
        })),
      },
    ]);
  };

  // Update Column Settings
  const updateColumnSettings = (colId, newSettings) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.id === colId
          ? { ...col, settings: { ...(col?.settings || {}), ...newSettings } }
          : col
      )
    );
    setSelectedSettings(
      selectedSettings?.id === colId
        ? {
            ...selectedSettings,
            settings: { ...selectedSettings?.settings, ...newSettings },
          }
        : selectedSettings
    );
  };

  // Update Component Settings
  const updateComponentSettings = (compId, colId, newSettings, type) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) => ({
        ...col,
        layout: col.layout.map((layout) =>
          layout?.id === colId
            ? {
                ...layout,
                components: layout.components.map((comp, index) =>
                  index === compId
                    ? {
                        ...comp,
                        settings: {
                          ...(comp.settings || {}),
                          ...(type === 'main' ? {} : newSettings),
                        },
                        data: {
                          ...(comp.data || {}),
                          ...newSettings,
                        },
                      }
                    : comp
                ),
              }
            : layout
        ),
      }))
    );

    setSelectedSettings(
      selectedSettings?.index === compId
        ? {
            ...selectedSettings,
            componentType: {
              ...selectedSettings?.componentType,
              data: {
                ...(selectedSettings?.componentType?.data || {}),
                ...newSettings,
              },
              settings:
                type === 'main'
                  ? selectedSettings?.componentType?.settings
                  : {
                      ...selectedSettings?.componentType?.settings,
                      ...newSettings,
                    },
            },
          }
        : selectedSettings
    );
  };

  // Handle Settings Selection
  const handleSelectColumn = (colId) => {
    const column = columns.find((col) => col.id === colId);
    setSelectedSettings({ type: 'column', ...column });
  };

  // Handle Dropped Item
  const handleDrop = (columnId, item) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) =>
        col.layout.some((c) => c.id === columnId)
          ? {
              ...col,
              layout: col.layout.map((c) =>
                c.id === columnId
                  ? {
                      ...c,
                      components: [
                        ...c.components,
                        { type: item.type, data: getDefaultData(item.type) },
                      ],
                    }
                  : c
              ),
            }
          : col
      )
    );
  };

  // Remove Column
  const removeColumn = (colId) => {
    setColumns(columns.filter((col) => col.id !== colId));
    if (selectedSettings?.id === colId) setSelectedSettings(null);
  };

  const handleMoveUp = (compIndex) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) => {
        const newLayout = col.layout.map((layout) => {
          const newComponents = layout.components.map((comp, index) => {
            if (index === compIndex - 1) {
              return layout.components[compIndex];
            } else if (index === compIndex) {
              return layout.components[compIndex - 1];
            }
            return comp;
          });
          return { ...layout, components: newComponents };
        });
        return { ...col, layout: newLayout };
      })
    );
  };

  const deleteTemplate = () => {
    setColumns([]);
    setSelectedSettings(null);
    handleDeleteTemplate();
  };

  const handleMoveDown = (compIndex) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) => {
        const newLayout = col.layout.map((layout) => {
          const newComponents = layout.components.map((comp, index) => {
            if (index === compIndex + 1) {
              return layout.components[compIndex];
            }
            if (index === compIndex) {
              return layout.components[compIndex + 1];
            }
            return comp;
          });
          return { ...layout, components: newComponents };
        });
        return { ...col, layout: newLayout };
      })
    );
  };

  const removeComponent = (columnId, index) => {
    setColumns((prevColumns) =>
      prevColumns.map((col) => ({
        ...col,
        layout: col.layout.map((c) =>
          c.id === columnId
            ? {
                ...c,
                components: c.components.filter(
                  (_, compIndex) => compIndex !== index
                ),
              }
            : c
        ),
      }))
    );
    if (
      selectedSettings?.type === 'component' &&
      selectedSettings.index === index
    ) {
      setSelectedSettings(null);
    }
  };

  const convertQuillContentToInlineStyles = (quillDelta) => {
    const converter = new QuillDeltaToHtmlConverter(quillDelta.ops, {
      inlineStyles: true,
    });
    return converter.convert();
  };

  const generateHTML = (columnsToExport) => {
    let htmlContent =
      '-<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office"><head><meta charset="UTF-8"><meta content="width=device-width, initial-scale=1" name="viewport"><meta name="x-apple-disable-message-reformatting"><meta http-equiv="X-UA-Compatible" content="IE=edge"><meta content="telephone=no" name="format-detection"><title></title><!--[if (mso 16)]><style type="text/css">a {text-decoration: none;}</style><![endif]--><!--[if gte mso 9]><style>sup { font-size: 100% !important; }</style><![endif]--><!--[if gte mso 9]><noscript><xml><o:OfficeDocumentSettings><o:AllowPNG></o:AllowPNG><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml></noscript><![endif]--></head><body> <div class="email-layout">';
    let cssContent = `
    .email-layout {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
    .column {
      border: none;
      padding: 8px;
      margin-bottom: 16px;
    }
    .component {
      border: none;
      border-radius: 4px;
      padding: 4px;
      margin: 4px;
    }`;

    columnsToExport.forEach((col, colIndex) => {
      htmlContent += `<div class="column" id="column-${colIndex}" style="display: flex; flex-wrap: wrap; padding: ${
        col?.settings?.padding || '8px'
      } gap: 4px; margin-bottom: 2px;">`;

      col.layout.forEach((layout, layoutIndex) => {
        const columnStyle = `flex: 1; max-width: ${
          100 / col.layout.length
        }%; padding: 6px; box-sizing: border-box;`;

        htmlContent += `<div class="layout-item" id="layout-${layoutIndex}" style="${columnStyle}">`;

        layout.components.forEach((comp, compIndex) => {
          const { settings = {}, data = {} } = comp;

          // Add component-specific HTML
          switch (comp.type) {
            case 'TEXT':
              const inlineStyledHTML = data.content?.ops
                ? convertQuillContentToInlineStyles(data.content)
                : data.content || 'Sample Text';

              htmlContent += `<div 
                class="component" 
                id="node-text-${compIndex}" 
                style="padding: ${settings.padding || '0px'}; text-align: ${
                settings.align || 'left'
              };"
              >
                ${inlineStyledHTML}
              </div>`;
              break;

            case 'IMAGE':
              htmlContent += `<div 
                  class="component" 
                  id="node-image-${compIndex}" 
                  style="padding: ${settings.padding || '0px'}; text-align: ${
                settings.alignement || 'center'
              };"
                >
                  <img 
                    src="${
                      data?.content?.fileUrl || '/assets/v3/img/placeholder.png'
                    }" 
                    alt="${data.content?.alt || 'Image'}" 
                    width="${settings.width || '40'}" 
                    height="${settings.height || '40'}"
                  />
                </div>`;
              break;

            case 'LOGO':
              htmlContent += `<div 
                  class="component" 
                  id="node-logo-${compIndex}" 
                  style="padding: ${settings.padding || '0px'}; text-align: ${
                settings.alignement || 'center'
              };"
                >
                  <img 
                    src="https://storage.googleapis.com/node-business-logos/${
                      businessPreference?.id
                    }.png"
                    alt="${data.content?.alt || 'Logo'}" 
                    width="${settings.width || '40px'}" 
                    height="${settings.height || '40px'}"
                  />
                </div>`;
              break;

            case 'SPACE':
              htmlContent += `<div 
                class="component" 
                id="node-space-${compIndex}" 
                style="height: ${settings?.height || '20px'};"
              ></div>`;
              break;

            case 'HTML':
              htmlContent += `<div 
                class="component" 
                id="node-html-${compIndex}" 
                style="padding: ${settings.padding || '0px'};"
              >
                ${data.content || ''}
              </div>`;
              break;

            case 'BUTTON':
              htmlContent += `<div 
                class="component" 
                id="node-button-${compIndex}" 
                style="padding: ${settings.padding || '8px'}; 
                       text-align: ${settings.alignement || 'center'}; 
                       display: flex; 
                       justify-content: ${settings.alignement || 'center'};"
              >
                <a
                  href="${data?.url || settings?.url || 'https://usenode.com'}"
                  target="_blank" 
                  rel="noopener noreferrer"
                  style="background-color: ${
                    settings.backgroundColor || '#007bff'
                  }; 
                         color: ${settings.color || '#fff'}; 
                         text-decoration: none;
                       text-align: center; display: inline-block; 
                         width: ${
                           settings.width ? `${settings.width}px` : 'auto'
                         }; 
                         height: ${
                           settings.height ? `${settings.height}px` : 'auto'
                         }; 
                        line-height: ${
                          settings.height ? `${settings.height}px` : 'auto'
                        };
                         border: none; 
                         border-radius: ${settings.borderRadius || '8px'}; 
                         font-weight: 600; 
                      
                         cursor: pointer;"
                >
                  ${data.label || 'Button'}
                </a>
              </div>`;
              break;

            default:
              htmlContent += `<div 
                class="component" 
                id="node-unknown-${compIndex}" 
                style="padding: ${settings.padding || '8px'};"
              >
                Unsupported Component
              </div>`;
              break;
          }
        });

        htmlContent += `</div>`;
      });

      htmlContent += `</div>`;
    });
    htmlContent += `<div style="padding: 12px;">
    <img src="https://usenode.com/assets/v3/img/icon-node-grey.png" style="width: 80px;" />
    <p  style="color: #a8a8a8; font-size: 10px; ">${
      '© ' + moment().format('YYYY') + ' - ' + t('footerBot')
    }</p>
    </div>`;
    htmlContent += `</div></body></html>`;
    return { htmlContent, cssContent };
  };

  const exportToFiles = () => {
    setColumns((prevColumns) => {
      const { htmlContent, cssContent } = generateHTML(prevColumns);
      handleSave(htmlContent, cssContent, prevColumns);
      return prevColumns;
    });
  };

  const getDefaultData = (type) => {
    switch (type) {
      case 'TEXT':
        return { content: t('text') };
      case 'IMAGE':
        return { src: '/assets/v3/img/placeholder.png', alt: 'Placeholder' };
      case 'BUTTON':
        return { label: t('clickMe'), url: '#' };
      case 'LOGO':
        return {
          src: `https://storage.googleapis.com/node-business-logos/${businessPreference?.id}.png`,
          alt: 'Logo',
          width: 40,
          height: 40,
        };
      case 'HTML':
        return { content: '' };
      default:
        return {};
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <Select
          selections={businessPreference?.emails
            ?.filter((e) => e.structureId === structureId)
            ?.map((e) => ({
              label: e.name,
              id: e.id,
              value: e.id,
            }))}
          noEmpty
          addNew={addTemplate}
          label={t('template')}
          value={selectedTemplate?.id || ''}
          onChange={(e, value) => {
            handleChangeTemplate(value);
          }}
        />
        <div className="col-9 row">
          <div className="col-4">
            <TextField
              fullWidth
              key={selectedTemplate?.id + 'name'}
              label={t('name')}
              value={selectedTemplate?.name}
              onChange={(e) => {
                handleChanges(e.target.value, 'name');
              }}
            />
          </div>
          <div className="col-4">
            <TextField
              fullWidth
              key={selectedTemplate?.id + 'subject'}
              label={t('subject')}
              value={selectedTemplate?.subject}
              onChange={(e) => {
                handleChanges(e.target.value, 'subject');
              }}
            />
          </div>
          <div className="col-2">
            <Select
              value={selectedTemplate?.lang}
              key={selectedTemplate?.id + 'lang'}
              onChange={(e, value) => {
                handleChanges(value, 'lang');
              }}
              field={{
                typeData: 'selectionNode',
                required: false,
              }}
              selections={[
                {
                  label: 'English',
                  value: 'en',
                  id: 'en',
                  color: businessPreference?.mainColor,
                },
                {
                  label: 'Français',
                  value: 'fr',
                  id: 'fr',
                  color: businessPreference?.mainColor,
                },
              ]}
              label={t('language')}
            />
          </div>

          <div className="col-2 mt-1 middle-content d-flex">
            <Button
              label={t('back')}
              fullWidth
              onClick={exportToFiles}
              buttonSx={{ mt: 0, mr: 2 }}
              endIcon={'SaveOutlined'}
            />
            <IconButton onClick={deleteTemplate}>
              <DeleteOutlined />
            </IconButton>
          </div>
        </div>
      </div>
      {isDarkMode ? (
        <div className="mt-5 align-c">{t('youMustBeLight')}</div>
      ) : (
        <div className="row">
          <div className="col-4">
            <h3>{t('layoutBlocks')}</h3>
            <div
              className="d-flex"
              style={{ opacity: 0.8, gap: '8px', marginLeft: '-6px' }}
            >
              <button
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                }}
                onClick={() => addColumn(1)}
              >
                <Tooltip title={t('clickToAdd')}>
                  <img
                    src="/assets/v3/components/1col.svg"
                    alt="1-col"
                    height={'35px'}
                    width={'100%'}
                  />
                </Tooltip>
              </button>
              <button
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                }}
                onClick={() => addColumn(2)}
              >
                <Tooltip title={t('clickToAdd')}>
                  <img
                    src="/assets/v3/components/2cols.svg"
                    alt="1-col"
                    height={'35px'}
                    width={'100%'}
                  />
                </Tooltip>
              </button>
              <button
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                }}
                onClick={() => addColumn(3)}
              >
                {' '}
                <Tooltip title={t('clickToAdd')}>
                  <img
                    src="/assets/v3/components/3cols.svg"
                    alt="3-cols"
                    height={'35px'}
                    width={'100%'}
                  />{' '}
                </Tooltip>
              </button>
            </div>
            <div>
              <h4>{t('components')}</h4>
              <div style={{ display: 'flex', gap: '8px' }}>
                <DraggableItem type={COMPONENTS.TEXT}>
                  <Tooltip title={t('dragInColToAdd')}>
                    <div className="d-flex justify-content-center align-c middle-content">
                      <TextFieldsOutlined sx={{ marginRight: '5px' }} />
                      {t('text')}
                    </div>
                  </Tooltip>
                </DraggableItem>
                <DraggableItem type={COMPONENTS.IMAGE}>
                  <Tooltip title={t('dragInColToAdd')}>
                    <div className="d-flex justify-content-center align-c middle-content">
                      <InsertPhotoOutlined sx={{ marginRight: '5px' }} />
                      {t('image')}
                    </div>
                  </Tooltip>
                </DraggableItem>
                <DraggableItem type={COMPONENTS.SPACE}>
                  <Tooltip title={t('dragInColToAdd')}>
                    <div className="d-flex justify-content-center align-c middle-content">
                      <SplitscreenOutlined sx={{ marginRight: '5px' }} />
                      {t('space')}
                    </div>
                  </Tooltip>
                </DraggableItem>
              </div>
              <div style={{ display: 'flex', gap: '8px' }}>
                <DraggableItem type={COMPONENTS.BUTTON}>
                  <Tooltip title={t('dragInColToAdd')}>
                    <div className="d-flex justify-content-center align-c middle-content">
                      <SmartButtonOutlined sx={{ marginRight: '5px' }} />
                      {t('button')}
                    </div>
                  </Tooltip>
                </DraggableItem>
                <DraggableItem type={COMPONENTS.HTML}>
                  <Tooltip title={t('dragInColToAdd')}>
                    <div className="d-flex justify-content-center align-c middle-content">
                      <CodeOutlined sx={{ marginRight: '5px' }} />
                      {t('html')}
                    </div>
                  </Tooltip>
                </DraggableItem>
                <DraggableItem type={COMPONENTS.LOGO}>
                  <Tooltip title={t('dragInColToAdd')}>
                    <div className="d-flex justify-content-center align-c middle-content">
                      <TonalityOutlined sx={{ marginRight: '5px' }} />
                      {t('logo')}
                    </div>
                  </Tooltip>
                </DraggableItem>
              </div>
            </div>
            <div className="mt-5">
              <h3>{t('settings')}</h3>
              {selectedSettings ? (
                selectedSettings?.type === 'column' ? (
                  <div>
                    <TextField
                      label={t('padding')}
                      value={selectedSettings?.settings?.padding || ''}
                      onChange={(e) =>
                        updateColumnSettings(selectedSettings?.id, {
                          padding: e.target.value,
                        })
                      }
                    />
                    <TextField
                      label={t('backgroundColor')}
                      value={
                        selectedSettings?.settings?.backgroundColor ||
                        'transparent'
                      }
                      onChange={(e) =>
                        updateColumnSettings(selectedSettings?.id, {
                          backgroundColor: e.target.value,
                        })
                      }
                      type="color"
                    />
                  </div>
                ) : (
                  <div>
                    {(selectedSettings?.componentType?.type === 'IMAGE' ||
                      selectedSettings?.componentType?.type === 'LOGO' ||
                      selectedSettings?.componentType?.type === 'BUTTON') && (
                      <div>
                        <TextField
                          label={t('width')}
                          value={
                            selectedSettings?.componentType?.settings?.width ||
                            ''
                          }
                          onChange={(e) =>
                            updateComponentSettings(
                              selectedSettings.index,
                              selectedSettings?.id,
                              {
                                width: e.target.value,
                              }
                            )
                          }
                          type="number"
                        />

                        <TextField
                          label={t('height')}
                          value={
                            selectedSettings?.componentType?.settings?.height ||
                            ''
                          }
                          onChange={(e) =>
                            updateComponentSettings(
                              selectedSettings.index,
                              selectedSettings?.id,
                              {
                                height: e.target.value,
                              }
                            )
                          }
                          type="number"
                        />
                        <Select
                          label={t('alignement')}
                          noEmpty
                          selections={[
                            {
                              id: 'left',
                              value: 'left',
                              label: t('left'),
                            },
                            {
                              id: 'center',
                              value: 'center',
                              label: t('center'),
                            },
                            {
                              id: 'right',
                              value: 'right',
                              label: t('right'),
                            },
                          ]}
                          onChange={(e, value) =>
                            updateComponentSettings(
                              selectedSettings.index,
                              selectedSettings?.id,
                              {
                                alignement: value,
                              }
                            )
                          }
                        />
                      </div>
                    )}
                    {selectedSettings?.componentType?.type === 'BUTTON' && (
                      <div>
                        <TextField
                          label={t('label')}
                          value={
                            selectedSettings?.componentType?.data?.label || ''
                          }
                          onChange={(e) =>
                            updateComponentSettings(
                              selectedSettings.index,
                              selectedSettings?.id,

                              { label: e.target.value }
                            )
                          }
                        />
                        <TextField
                          label={t('action')}
                          type="url"
                          value={
                            selectedSettings?.componentType?.data?.url || ''
                          }
                          onChange={(e) =>
                            updateComponentSettings(
                              selectedSettings.index,
                              selectedSettings?.id,

                              { url: e.target.value }
                            )
                          }
                        />

                        <TextField
                          label={t('backgroundColor')}
                          value={
                            selectedSettings?.componentType?.settings
                              ?.backgroundColor || ''
                          }
                          onChange={(e) =>
                            updateComponentSettings(
                              selectedSettings.index,
                              selectedSettings?.id,
                              {
                                backgroundColor: e.target.value,
                              }
                            )
                          }
                          type="color"
                        />
                        <TextField
                          label={t('textColor')}
                          value={
                            selectedSettings?.componentType?.settings?.color ||
                            ''
                          }
                          onChange={(e) =>
                            updateComponentSettings(
                              selectedSettings.index,
                              selectedSettings?.id,
                              {
                                color: e.target.value,
                              }
                            )
                          }
                          type="color"
                        />
                      </div>
                    )}
                  </div>
                )
              ) : (
                <p>{t('selectToDisplay')}</p>
              )}
            </div>
          </div>
          <div
            style={{ height: '50vh', overflow: 'scroll' }}
            className="col-8 mt-4"
          >
            <div
              style={{
                borderRadius: '12px',
                backgroundColor: isDarkMode ? '#1a1a1a' : '#f1f1f1',
                padding: '16px',
              }}
            >
              {columns?.map((col) => (
                <div
                  key={col.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '6px',
                    borderRadius: '5px',
                    padding: col?.settings?.padding || '8px',
                    width: '100%',
                    border: '0.5px dashed #69696980',
                    marginBottom: '4px',
                    position: 'relative',
                    backgroundColor:
                      col?.settings?.backgroundColor || 'transparent',
                  }}
                >
                  <IconButton
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '-19px',
                      border: '1px solid #69696980',
                      backgroundColor: isDarkMode ? '#1a1a1a' : '#FFFFFF',
                    }}
                    onClick={() => handleSelectColumn(col.id)}
                  >
                    <SettingsOutlined />
                  </IconButton>
                  <IconButton
                    style={{
                      position: 'absolute',
                      top: '55px',
                      right: '-19px',
                      border: '1px solid #69696980',
                      backgroundColor: isDarkMode ? '#1a1a1a' : '#FFFFFF',
                    }}
                    onClick={() => removeColumn(col.id)}
                  >
                    <DeleteForeverOutlined />
                  </IconButton>
                  {col.layout.map((column, layoutIndex) => (
                    <DroppableArea
                      key={column.id}
                      onDrop={(item) => handleDrop(column.id, item)}
                    >
                      {column?.components?.map((comp, index) => (
                        <div
                          key={index}
                          style={{
                            flex: 1,
                            minWidth: '150px',
                            minHeight: '20px',
                            padding: '6px',
                            border:
                              selectedSettings?.index === index
                                ? '1px solid transparent'
                                : '1px solid #f1f1f1',
                            backgroundColor:
                              selectedSettings?.index === index
                                ? '#f1f1f1'
                                : 'transparent',
                            borderRadius: '4px',
                            margin: '2px',
                            position: 'relative',
                          }}
                        >
                          {comp.type === 'TEXT' && (
                            <TextComponent
                              tags={tags}
                              setColumns={setColumns}
                              col={col?.id}
                              layoutId={column?.id}
                              selectedSettings={selectedSettings}
                              comp={index}
                              layoutIndex={layoutIndex}
                              value={comp?.data?.content}
                              handleDataChange={handleDataColsChange}
                              columns={columns}
                            />
                          )}
                          {comp.type === 'IMAGE' && (
                            <ImageComponent
                              setColumns={setColumns}
                              col={col?.id}
                              layoutId={column?.id}
                              selectedSettings={selectedSettings}
                              comp={index}
                              layoutIndex={layoutIndex}
                              value={comp?.data?.content}
                              handleDataChange={handleDataColsChange}
                              columns={columns}
                              width={comp?.settings?.width}
                              height={comp?.settings?.height}
                              alignement={comp?.settings?.alignement}
                            />
                          )}

                          {comp.type === 'SPACE' && (
                            <div style={{ height: '50px' }} />
                          )}
                          {comp.type === 'LOGO' && (
                            <div
                              style={{
                                height: '100%',
                                width: '100%',
                                alignContent: comp?.settings?.alignement,
                                display: 'flex',
                                justifyContent: comp?.settings?.alignement,
                              }}
                            >
                              <img
                                src={comp?.data?.src}
                                alt="Logo"
                                height={comp?.settings?.height}
                                width={comp?.settings?.width}
                                style={{ borderRadius: '4px' }}
                              />
                            </div>
                          )}
                          {comp.type === 'BUTTON' && (
                            <ButtonComponent
                              setColumns={setColumns}
                              col={col?.id}
                              layoutId={column?.id}
                              selectedSettings={selectedSettings}
                              comp={index}
                              layoutIndex={layoutIndex}
                              value={comp?.data?.label}
                              handleDataChange={handleDataColsChange}
                              columns={columns}
                              width={comp?.settings?.width}
                              height={comp?.settings?.height}
                              alignement={comp?.settings?.alignement}
                              backgroundColor={comp?.settings?.backgroundColor}
                              color={comp?.settings?.color}
                            />
                          )}
                          <IconButton
                            style={{
                              position: 'absolute',
                              top: '3px',
                              right: '91px',
                              backgroundColor: isDarkMode
                                ? '#1a1a1a'
                                : '#FFFFFF',
                            }}
                            onClick={() => {
                              handleMoveUp(index);
                            }}
                          >
                            <ArrowDropUp />
                          </IconButton>
                          <IconButton
                            style={{
                              position: 'absolute',
                              top: '3px',
                              right: '62px',
                              backgroundColor: isDarkMode
                                ? '#1a1a1a'
                                : '#FFFFFF',
                            }}
                            onClick={() => {
                              handleMoveDown(index);
                            }}
                          >
                            <ArrowDropDown />
                          </IconButton>

                          <IconButton
                            style={{
                              position: 'absolute',
                              top: '3px',
                              right: '35px',
                              backgroundColor: isDarkMode
                                ? '#1a1a1a'
                                : '#FFFFFF',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedSettings({
                                type: 'component',
                                id: column.id,
                                index,
                                componentType: comp,
                              });
                            }}
                          >
                            <SettingsOutlined />
                          </IconButton>
                          <IconButton
                            style={{
                              position: 'absolute',
                              top: '3px',
                              right: '8px',
                              backgroundColor: isDarkMode
                                ? '#1a1a1a'
                                : '#FFFFFF',
                            }}
                            onClick={() => removeComponent(column.id, index)}
                          >
                            <DeleteForeverOutlined />
                          </IconButton>
                        </div>
                      ))}
                    </DroppableArea>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </DndProvider>
  );
};

export default BuilderEmail;
