// Libraries
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import Popper from '@mui/material/Popper';
import MainSearch from '../../components/@generalComponents/layout/MainSearch/MainSearch';
import moment from 'moment';
import { Divider } from '@mui/material';
import {
  collection,
  doc,
  query,
  where,
  onSnapshot,
  orderBy,
} from 'firebase/firestore';

// Utilities
import { db } from '../../firebase';

// Components
import HistoryMessage from './HistoryMessage';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';

const MessagesFeed = ({
  userId,
  elementId,
  elementType,
  heightPercentage,
  fromList,
  elementPath,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [logMessage, setLogMessage] = useState('');
  const [logMessageServer, setLogMessageServer] = useState('');
  const [groupedLogs, setGroupedLogs] = useState({});
  const { structureId } = useParams();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const logsContainerRef = useRef(null);
  const quillRef = useRef(null);
  const currentPath = window.location.pathname;
  const currentPageTitle = document.title;

  const [logs, setLogs] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [mentionSuggestions, setMentionSuggestions] = useState([]);
  const [valueServer, setValueServer] = useState('');
  const [openPopper, setOpenPopper] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [clicked, setClicked] = useState(false);

  const currentUser = useSelector((state) => state.core.user);
  const businessStructures = useSelector(
    (state) => state.core.businessStructure
  )?.structures;
  const businessPreference = useSelector((state) => state.core.businessData);
  const businessFirebaseID =
    businessPreference?.id || localStorage.getItem('businessId');

  // Convert plain text to escaped HTML
  const escapeHtml = (text) => {
    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;',
    };
    return text.replace(/[&<>"']/g, (m) => map[m]);
  };

  // Convert escaped HTML back to plain text
  const unescapeHtml = (text) => {
    const map = {
      '&amp;': '&',
      '&lt;': '<',
      '&gt;': '>',
      '&quot;': '"',
      '&#039;': "'",
    };
    return text.replace(/&(amp|lt|gt|quot|#039);/g, (m) => map[m]);
  };

  const handleChange = (value) => {
    const isInitialAt = value === '@' || value === '<p>@</p>';

    if (isInitialAt) {
      setAnchorEl(quillRef.current?.editor?.container);
      setOpenPopper(true);
    } else {
      const lastWord = value.split(/\s+/).pop();
      const isMentionTriggered =
        lastWord.startsWith('@') || lastWord.startsWith('<p>@');

      if (isMentionTriggered) {
        setAnchorEl(quillRef.current?.editor?.container);
        setOpenPopper(true);
      } else {
        setOpenPopper(false);
      }
    }

    const editor = quillRef.current?.getEditor();
    const rawHTML = editor?.root.innerHTML;

    // Regex to detect existing <a> tags for mentions
    const mentionLinkRegex = /<a\s+[^>]*>@([^<]+)<\/a>/g;
    const existingMentions = [...rawHTML.matchAll(mentionLinkRegex)];

    let updatedHTML = rawHTML;

    // Preserve existing mentions by replacing the plain text back with <a> tags
    existingMentions.forEach((mentionMatch) => {
      const mentionHTML = mentionMatch[0]; // Full <a> tag
      const mentionText = mentionMatch[1]; // Mention text without <a> tag

      const escapedText = escapeHtml(mentionText);
      updatedHTML = updatedHTML.replace(
        new RegExp(`@${escapedText}`, 'g'), // Match plain text mention
        mentionHTML
      );
    });

    // Detect new mentions being typed
    const mentionRegex = /@([a-zA-Z0-9À-ÖØ-öø-ÿ\s&]+)/g;
    const newMentions = [...value.matchAll(mentionRegex)];

    newMentions.forEach((match) => {
      const mentionName = match[1].trim();

      // Check if this mention is already converted to an <a> tag
      const alreadyExists = existingMentions.some((m) => m[1] === mentionName);
      if (alreadyExists) {
        return;
      }

      // Find the mention in the suggestion list
      const storedMention = mentionSuggestions.find(
        (m) => unescapeHtml(m.name) === mentionName
      );

      if (storedMention) {
        const escapedMentionName = escapeHtml(mentionName);

        updatedHTML = updatedHTML.replace(
          `@${escapedMentionName}`,
          `<a id=${storedMention.documentId} path=${storedMention.path} structure=${storedMention.structureId} collection=${storedMention.collectionField}> @${escapedMentionName} </a>`
        );
      }
    });

    // Update state
    setValueServer(updatedHTML); // Processed HTML for the server
    setLogMessage(value);
  };
  const processMentions = async (description, mentionSuggestions) => {
    const mentionRegex = /@(\w+)/g;
    let updatedDescription = description;

    const matches = [...description.matchAll(mentionRegex)];

    const promises = matches.map(async (match) => {
      const mentionName = match[1].trim();
      const mentionData = mentionSuggestions.find(
        (m) => m.name === mentionName
      );

      if (mentionData) {
        updatedDescription = updatedDescription.replace(
          `@${mentionName}`,
          `<a id="${mentionData.documentId}" path="${mentionData.path}" structure="${mentionData.structureId}" collection="${mentionData.collectionField}">@${mentionName}</a>`
        );
      }
    });

    await Promise.all(promises);

    return updatedDescription;
  };

  const toolbarOptions = [
    ['bold', 'italic', 'underline'],
    ['link'],
    // ['link', 'image', 'video', 'formula'],
    [{ size: ['small', 'medium'] }],
    [{ color: [] }],
    [{ align: [] }],
  ];

  useEffect(() => {
    let unsubscribe;
    if (elementId && elementType) {
      const getLogs = async () => {
        const businessRef = doc(db, 'businessesOnNode', businessFirebaseID);

        let q;
        try {
          q = query(
            collection(db, elementPath, 'logs'),
            where('isDone', '==', false),
            where('ownerId', '==', businessRef),
            orderBy('timeStamp', 'asc')
          );

          unsubscribe = onSnapshot(
            q,
            (querySnapshot) => {
              const logs = [];
              querySnapshot.forEach((doc) => {
                logs?.push({ ...doc.data(), id: doc.id });
              });
              setLogs(logs);
            },
            (error) => {
              console.error('Error fetching logs: ', error);
            }
          );
        } catch (error) {
          console.error('Error fetching logs', error);
          dispatch(setGeneralStatus({ status: 'error', error: error }));
        }
      };

      getLogs();

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [elementId, elementType, userId]);

  const groupLogsByDateAndType = (logs) => {
    const groupedLogs = {};

    logs.forEach((log) => {
      const date = moment
        .unix(log?.timeStamp?.seconds || log?.timeStamp?._seconds || '')
        .format('YYYY-MM-DD-HH:mm:ss');
      const type = log?.type;

      if (!groupedLogs[date]) {
        groupedLogs[date] = {};
      }

      if (!groupedLogs[date][type]) {
        groupedLogs[date][type] = { count: 0, logs: [] };
      }

      groupedLogs[date][type].count += 1;
      groupedLogs[date][type].logs.push(log);
    });

    return groupedLogs;
  };

  useEffect(() => {
    if (logs?.length > 0) {
      const groupedLogs = groupLogsByDateAndType(logs);
      setGroupedLogs(groupedLogs);
    }
  }, [logs]);

  const handleLogCreation = async () => {
    if (logMessage.trim() === '' || !elementId) return;

    let elementFinalType = elementType;

    if (
      elementType === 'cardsuninvoiced' ||
      elementType === 'cardsinvoiced' ||
      elementType === 'cardsexpense'
    ) {
      elementFinalType = 'cards';
    }

    const finalValue = await processMentions(logMessage, mentionSuggestions);
    setValueServer(finalValue);

    try {
      await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/log`,
        body: {
          elementId: elementId,
          elementPath: elementPath,
          description: finalValue || logMessage,
          elementFinalType: elementFinalType + ':message',
          documentPath:
            '/app/element/' +
            elementFinalType +
            '/' +
            structureId +
            '/' +
            elementId,
          name: currentUser?.displayName,
          userContactId: userId || null,
        },
      });

      setLogMessage('');
      setMentionSuggestions([]);
      setValueServer('');
      setLogMessageServer('');
    } catch (error) {
      console.error('Error creating log:', error);
    }
  };

  const handleKeyDown = () => {
    handleLogCreation();
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (logsContainerRef.current) {
        logsContainerRef.current.scrollTop =
          logsContainerRef.current.scrollHeight;
      }
    });

    if (logsContainerRef.current) {
      observer.observe(logsContainerRef.current, {
        childList: true,
      });
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (logsContainerRef.current && logs.length > 0) {
      setTimeout(() => {
        logsContainerRef.current.scrollTop =
          logsContainerRef.current.scrollHeight;
      }, 0);
    }
  }, [logs]);

  useEffect(() => {
    if (logsContainerRef.current && logs.length > 0) {
      setTimeout(() => {
        logsContainerRef.current.scrollTop =
          logsContainerRef.current.scrollHeight;
      }, 0);
    }
  }, [logs]);

  const handleClose = () => setOpen(false);

  const handleElementSelect = (elementPath, elementname) => {
    const updatedValue = logMessage?.replace(
      /@[^\s]*\s?$/,
      ` @${elementname} `
    );
    handleChange(updatedValue);

    setValueServer(
      valueServer +
        `<a id=${elementPath
          .split('/')
          .pop()} path=${elementPath} from=${currentPath} structure=${
          selectedCategory?.id
        } collection=${selectedCategory?.collection} > @${elementname} </a>`
    );

    //add to mentions suggestions
    const newMention = {
      name: elementname,
      path: elementPath,
      structureId: selectedCategory?.id,
      documentId: elementPath.split('/').pop(),
      collectionField: selectedCategory?.collection,
    };
    setMentionSuggestions((prevSuggestions) => [
      ...prevSuggestions,
      newMention,
    ]);

    handleClose();
    setTimeout(() => {
      const quill = quillRef.current?.getEditor();
      if (quill) {
        const contentLength = quill.getLength();
        quill.setSelection(contentLength - 1, 0);
      }
    }, 500);
  };

  const handleStructureSelect = (elementCollection, elementId) => {
    setSelectedCategory({ id: elementId, collection: elementCollection });
    setOpen(true);
    setOpenPopper(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Detect Cmd + Enter (Mac) or Ctrl + Enter (Windows/Linux)
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        event.preventDefault();
        if (quillRef.current?.editor?.hasFocus()) {
          handleLogCreation();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [logMessage, logMessageServer]);

  return (
    <>
      <Dialog style={{ zIndex: 100000 }} open={open} onClose={handleClose}>
        <DialogContent>
          <div style={{ width: '450px', height: '450px' }}>
            <MainSearch
              fromExternal
              setClicked={setClicked}
              clicked={clicked}
              structureIden={selectedCategory?.id}
              onSelectReturn={handleElementSelect}
              structureCollection={selectedCategory?.collection}
            />
          </div>
        </DialogContent>
      </Dialog>
      <Popper
        open={openPopper}
        anchorEl={anchorEl}
        style={{
          zIndex: 10000,
          backgroundColor: isDarkMode ? 'rgb(51,51,51)' : '#fff',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
          borderRadius: '10px',
        }}
      >
        <List>
          {businessStructures?.map((category) => (
            <ListItem divider dense key={category.id} disablePadding>
              <ListItemButton
                onClick={() =>
                  handleStructureSelect(
                    category?.collectionField,
                    category?.id,
                    'element'
                  )
                }
              >
                <ListItemText primary={category?.name} />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem divider dense key={'employees'} disablePadding>
            <ListItemButton
              onClick={() =>
                handleStructureSelect(
                  'employees',
                  businessPreference?.id,
                  'tag'
                )
              }
            >
              <ListItemText primary={t('teamMembers')} />
            </ListItemButton>
          </ListItem>
        </List>
      </Popper>
      <div style={{ position: 'relative' }}>
        <div
          style={{
            paddingLeft: '22px',
            overflowY: 'auto',
            overflowX: 'hidden',
            height: `calc(${
              Number(heightPercentage) > 50
                ? Number(heightPercentage) - 9
                : heightPercentage + 6
            }vh - 140px)`,
            paddingBottom: '0px',
          }}
          ref={logsContainerRef}
        >
          {Object.keys(groupedLogs).map((date) =>
            Object.keys(groupedLogs[date]).map((type) => (
              <div key={`${date}-${type}`}>
                <HistoryMessage
                  userId={groupedLogs[date][type].logs[0]?.assignedToId}
                  messageId={groupedLogs[date][type].logs[0]?.id}
                  type={groupedLogs[date][type].logs[0]?.type}
                  text={`${groupedLogs[date][type].logs[0]?.description || ''}`}
                  badge={groupedLogs[date][type].count}
                  others={groupedLogs[date][type]?.logs?.slice(1)}
                  name={groupedLogs[date][type].logs[0]?.name}
                  avatar={groupedLogs[date][type].logs[0]?.avatar}
                  timestamp={moment
                    .unix(
                      groupedLogs[date][type].logs[0]?.timeStamp?.seconds ||
                        groupedLogs[date][type].logs[0]?.timeStamp?._seconds ||
                        ''
                    )
                    .fromNow()}
                />
              </div>
            ))
          )}
        </div>
        {!fromList && (
          <div
            style={{
              position: 'fixed',
              width: '100%',
              marginLeft: '-18px',
              paddingLeft: '20px',
              paddingRight: '20px',
              paddingBottom: '5px',
              overflow: 'hidden',
              bottom: 0,
              backgroundColor: isDarkMode ? '#1a1a1a' : '#FFFFFF',
            }}
          >
            <Divider component="div" />

            <div style={{ position: 'relative' }} className="mt-2">
              <ReactQuill
                ref={quillRef}
                value={logMessage}
                key={elementId + 'messages'}
                onChange={handleChange}
                theme="snow"
                placeholder={t('enterTextType')}
                style={{ minHeight: '140px' }}
                modules={{
                  toolbar: toolbarOptions,
                }}
              />
              <div style={{ position: 'absolute', bottom: 10, right: 10 }}>
                <IconButton
                  color="white"
                  style={{
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    backgroundColor: businessPreference?.secColor,
                  }}
                  onClick={() => handleKeyDown()}
                >
                  <ArrowUpwardOutlinedIcon />
                </IconButton>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MessagesFeed;
