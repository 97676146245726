import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import IconUploader from '../../../components/@generalComponents/IconUploader';

const ImageComponent = ({
  tags = [],
  comp,
  col,
  value,
  handleDataChange,
  layoutId,
  layoutIndex,
  selectedSettings,
  width,
  height,
  alignement,
}) => {
  const businessPreference = useSelector((state) => state.core.businessData);
  const [displayImg, setDisplayImg] = useState(true);

  const handleUploadComplete = (data) => {
    handleDataChange(col, comp, layoutIndex, data);
    setDisplayImg(true);
  };

  return (
    <div
      style={{
        borderRadius: '4px',
        height: '100%',
        width: '100%',
        alignContent: alignement,
        display: 'flex',
        justifyContent: alignement,
      }}
    >
      {displayImg && value ? (
        <img
          src={value?.fileUrl}
          alt="img"
          style={{
            width: Number(width) || '100%',
            height: Number(height) || '100%',
          }}
        />
      ) : (
        <IconUploader
          elementType={'image'}
          fieldType={'media-single'}
          onComplete={handleUploadComplete}
          fieldKey={comp + col + layoutId}
          businessId={businessPreference?.id}
          size={'large'}
          value={value}
        />
      )}
    </div>
  );
};

export default ImageComponent;
