import React from 'react';

const NodeBOS = ({ primaryColor = '#d9ef00', secondaryColor = '#dd1a00' }) => {
  return (
    <svg
      id="Calque_BOS"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 35.14 36"
      width="100%"
      height="100%"
    >
      <defs>
        <style>
          {`
            .cls-1-BOS {
              fill: ${primaryColor};
   
            }
            .cls-2-BOS {
              fill: ${secondaryColor};
            }
          `}
        </style>
        <filter
          id="drop-shadow-1"
          x="0"
          y="0"
          width="34"
          height="36"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="2" dy="2" />
          <feGaussianBlur result="blur" stdDeviation="2" />
          <feFlood floodColor="#515151" floodOpacity="0.3" />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Calque_1-BOS">
        <rect
          className="cls-2-BOS"
          x="14.29"
          y="11.92"
          width="20.85"
          height="20.85"
          rx="3.14"
          ry="3.14"
        />
        <path
          className="cls-1-BOS"
          d="M5.5,7.54c.18-2.65,3.19-4.1,5.37-2.59l13.24,9.11c2.18,1.49,1.92,4.77-.46,5.92l-14.34,6.92c-2.39,1.15-5.12-.7-4.92-3.32l1.11-16.03h.02s-.02,0-.02,0Z"
        />
      </g>
    </svg>
  );
};

export default NodeBOS;
