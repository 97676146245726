import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import chroma from 'chroma-js';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';

//components
import MainLayoutV2 from '../../layouts/MainLayoutV2';
import DrawerSide from '../../stories/layout-components/DrawerSide';
import Block from '../../stories/layout-components/Block';
import GeneralText from '../../stories/general-components/GeneralText';
import TextFieldMUI from '@mui/material/TextField';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import Checkbox from '../../stories/general-components/Checkbox';
import TextField from '../../stories/general-components/TextField';
import Button from '../../stories/general-components/Button';
import { setGeneralStatus } from '../../redux/actions-v2/coreAction';
import { AttachMoney, PaymentOutlined } from '@mui/icons-material';
import FlashIcon from '../../components/FlashIcon';
import RiseIcon from '../../components/RiseIcon';
import TrialIcon from '../../components/TrialIcon';

const Settings = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const businessPreference = useSelector((state) => state.core.businessData);
  const currentUser = useSelector((state) => state.core.user);
  const mainColor = businessPreference?.mainColor || '#000000';
  const darkColor = chroma(mainColor).darken(0.99).hex();

  const currentDay = moment().format('DD');
  const billingReset = businessPreference?.billingReset || '01';
  const formattedBillingDate =
    parseInt(billingReset) < parseInt(currentDay)
      ? moment().add(1, 'months').format(' MMM YYYY')
      : moment().format(' MMM YYYY');

  const [tags, setTags] = useState([]);
  const [methods, setMethods] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [formData, setFormData] = useState(null);
  const [lists, setLists] = useState([]);
  const [displayInvoices, setDisplayInvoices] = useState(false);
  const [changes, setChanges] = useState({
    groups: false,
    tags: false,
    preferences: false,
  });

  const location = useLocation();

  const [searchParams] = useSearchParams();
  const activeIndex = parseInt(searchParams.get('tab')) || 0;

  const countPaymentMethods = methods?.length || 0;

  const getMethods = async () => {
    try {
      const intent = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/payments`,
        // noAuth: true,
        body: {
          type: 'methods',
          businessId: businessPreference?.businessCustomerId,
        },
        reduxDispatcher: dispatch,
        loadingMessage: `${t('gettingCard')}`,
      });
      setMethods(intent?.paymentMethods?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (businessPreference?.businessCustomerId) {
      getMethods();
    }
  }, [businessPreference?.businessCustomerId]);

  const handleOpenCard = (card) => {
    window.open(
      `${window.location.origin}/redirect/6jyRhYMJ45SObIlg89EK/cardsinvoiced/KmyEevTikiqoH0nrY4qW/${card?.id}?accessCode=${card?.accessCode}&shared=true`,
      '_blank'
    );
  };

  const getLists = async () => {
    try {
      const lists = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/billing`,
        body: {},
      });
      setLists(lists?.cards);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (businessPreference?.id) {
      getLists();
    }
  }, [businessPreference?.id]);

  useEffect(() => {
    const formatedTags = businessPreference?.tags?.map((tag) => ({
      label: tag,
      value: tag,
      id: tag,
    }));
    setTags(formatedTags || []);
    setSelectedGroup(businessPreference?.groups?.[0] || null);
    setFormData({
      ...businessPreference?.preferences,
      ipRestriction: businessPreference?.ipRestriction ? true : false,
      ipAddress: businessPreference?.ipRestriction + '***.**.***',
    });
  }, [businessPreference?.id, businessPreference?.internalVersion]);

  const handleSaveTags = async () => {
    let listTags = [];
    try {
      if (tags?.length > 0) {
        listTags = tags.map((tag) => tag.value);
      }
      await nodeAxiosFirebase({
        t,
        method: 'PATCH',
        url: `business/general`,
        body: {
          tags: listTags,
        },
      });
    } catch (error) {
      console.error('Error saving tags');
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };

  const handleSavePreferences = async () => {
    try {
      if (changes?.preferences) {
        await nodeAxiosFirebase({
          t,
          method: 'PATCH',
          url: `business/general`,
          body: {
            businessData: {
              ...businessPreference,
              ipRestriction: formData?.ipRestriction,
              preferences: {
                devMode:
                  formData?.devMode ?? businessPreference?.preferences?.devMode,
                fieldsView:
                  formData?.fieldsView ??
                  businessPreference?.preferences?.fieldsView,
                allowNodeAccess:
                  formData?.allowNodeAccess ??
                  businessPreference?.preferences?.allowNodeAccess ??
                  false,
              },
            },
          },
        });
      }
      if (changes?.groups) {
        handleSaveGroups();
      }
      if (changes?.tags) {
        handleSaveTags();
      }
      setChanges({
        groups: false,
        tags: false,
        preferences: false,
      });
      toast.success(t('saved'));
    } catch (error) {
      console.error('Error saving tags', error);
    }
  };

  const handleSavePreferencesIP = async (ipRestriction) => {
    try {
      const formattedIP = ipRestriction.trim();
      const ipArray = formattedIP.split('.');
      if (ipArray.length !== 4) {
        return;
      }
      for (let i = 0; i < ipArray.length; i++) {
        if (isNaN(ipArray[i]) || ipArray[i] < 0 || ipArray[i] > 255) {
          return;
        }
      }
      await nodeAxiosFirebase({
        t,
        method: 'PATCH',
        url: `business/general`,
        body: {
          businessData: {
            ...businessPreference,
            ipRestriction: formattedIP,
          },
        },
      });

      toast.success(t('saved'));
    } catch (error) {
      console.error('Error saving IP', error);
    }
  };

  const handleSaveGroups = async () => {
    try {
      await nodeAxiosFirebase({
        t,
        method: 'PATCH',
        url: `business/general`,
        body: {
          groupData: selectedGroup,
        },
      });
    } catch (error) {
      console.error('Error saving groups');
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };

  useEffect(() => {
    const currentHistoryToAdd = {
      pathname: location.pathname,
      url: location.pathname + location.search,
      name: t('settings'),
      moduleName: t('settings'),
      structureId: '',
      businessId: businessPreference?.id,
      timeStamp: moment().format('HH:mm:ss'),
    };

    let existingHistory = JSON.parse(localStorage.getItem('history'));

    if (!Array.isArray(existingHistory)) {
      existingHistory = [];
    }

    // Remove any existing history with the same pathname
    existingHistory = existingHistory.filter(
      (history) => history.pathname !== location.pathname
    );

    const newHistory = [...existingHistory, currentHistoryToAdd];
    if (newHistory.length > 15) {
      newHistory.shift();
    }

    localStorage.setItem('history', JSON.stringify(newHistory));
  }, [location.pathname]);

  const handleCopyLinkEmployee = () => {
    const link = `https://usenode.com/welcome/${businessPreference?.id}`;
    navigator.clipboard.writeText(link);
    toast.success(t('copied'));
  };

  const handleCopyEntity = () => {
    const link = `${businessPreference?.id}`;
    navigator.clipboard.writeText(link);
    toast.success(t('copied'));
  };

  const handleAddTag = (event) => {
    if (event.key === 'Enter' && event.target.value) {
      const newTag = {
        label: event.target.value,
        value: event.target.value,
        id: event.target.value,
      };
      setTags([...tags, newTag]);
      event.target.value = '';
    }
    setChanges({
      ...changes,
      tags: true,
    });
  };

  const handleSaveField = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
    setChanges({
      ...changes,
      preferences: true,
    });
  };

  const handleSelectGroup = (group) => {
    setSelectedGroup(group);
  };

  const handleAddGroup = () => {
    handleSaveGroups();
    setIsOpen(false);
    setChanges({
      ...changes,
      groups: true,
    });
  };

  const handleCloseDrawer = () => {
    setIsOpen(false);
    setSelectedGroup(businessPreference?.groups?.[0]);
  };

  return (
    <MainLayoutV2
      icon="SettingsOutlined"
      pageTitle={t('settings')}
      subTitle={t('manageYourAccount')}
      actions={{
        save: () => {
          handleSavePreferences();
        },
        add: () => {
          setSelectedGroup({
            name: '',
            description: '',
            identifiant: '',
          });
          setIsOpen(true);
        },
      }}
      selectedTab={activeIndex}
    >
      <DrawerSide
        title={t('addGroup')}
        size="small"
        isDrawerOpen={isOpen}
        handleDrawerClose={handleCloseDrawer}
        noAction
      >
        <div>
          <TextField
            fullWidth
            label={t('name')}
            value={selectedGroup?.name}
            onChange={(e) =>
              setSelectedGroup({ ...selectedGroup, name: e.target.value })
            }
          />
          <TextField
            fullWidth
            label={t('description')}
            value={selectedGroup?.description}
            onChange={(e) =>
              setSelectedGroup({
                ...selectedGroup,
                description: e.target.value,
              })
            }
          />
          <TextField
            fullWidth
            label={t('identifiant')}
            value={selectedGroup?.identifiant}
            onChange={(e) =>
              setSelectedGroup({
                ...selectedGroup,
                identifiant: e.target.value?.toUpperCase()?.trim(),
              })
            }
          />
          <Button
            buttonSx={{ mt: 2 }}
            variant="contained"
            color="primary"
            label={t('add')}
            onClick={() => {
              handleAddGroup();
            }}
            fullWidth
          />
        </div>
      </DrawerSide>
      <Block height={1} heightPercentage={98} noBorder>
        <Box display="flex" p={3}>
          <Box width="30%">
            <div
              style={{
                height: '150px',
                borderRadius: '12px',
                overflow: 'hidden',
              }}
              className="row"
            >
              <div>
                <img
                  style={{ width: '40px', height: '40px' }}
                  alt="Logo"
                  src={`https://storage.googleapis.com/node-business-logos/${businessPreference?.id}.png`}
                />
                <div>
                  <GeneralText
                    primary={true}
                    size="bold"
                    fontSize="16px"
                    text={businessPreference?.name}
                    classNameComponent="mt-2"
                  />
                  <GeneralText
                    primary={true}
                    size="medium"
                    fontSize="11px"
                    text={businessPreference?.address}
                    classNameComponent="mt-2"
                  />
                  <GeneralText
                    primary={true}
                    size="medium"
                    fontSize="11px"
                    text={businessPreference?.phone}
                  />

                  <GeneralText
                    primary={true}
                    size="medium"
                    fontSize="11px"
                    text={businessPreference?.email}
                    classNameComponent="mt-2"
                  />
                  <GeneralText
                    primary={true}
                    size="medium"
                    fontSize="11px"
                    text={businessPreference?.website}
                  />
                </div>
              </div>
            </div>
          </Box>
          <Box width="30%">
            <div
              style={{
                overflow: 'hidden',
                paddingLeft: '25px',
                paddingRight: '25px',
                position: 'relative',
              }}
            >
              <div
                style={{
                  backgroundColor: businessPreference?.secColor + '15',
                  height: '150px',
                  borderRadius: '12px',
                  overflow: 'hidden',
                  position: 'relative',
                }}
                className="row"
              >
                <div style={{ position: 'absolute', bottom: 0, right: '-71%' }}>
                  <AttachMoney
                    style={{
                      fontSize: '150px',
                      marginTop: '30px',
                    }}
                    htmlColor={businessPreference?.secColor + '50'}
                  />
                </div>

                <div className="mt-4 d-flex px-4 ">
                  <div className=" mt-2 col-9">
                    <GeneralText
                      primary={true}
                      size="regular"
                      fontSize="13px"
                      text={t('nextPayment')}
                      classNameComponent="mb-2 grey-text "
                    />
                    <div className="">
                      <GeneralText
                        primary={true}
                        size="medium"
                        fontSize="14px"
                        text={
                          t('onDate') +
                          ' ' +
                          billingReset +
                          formattedBillingDate
                        }
                        classNameComponent="mb-3"
                      />
                    </div>{' '}
                  </div>
                  <div className="col-3 d-flex mt-4">
                    <PaymentOutlined />
                    <GeneralText
                      primary={true}
                      size="medium"
                      fontSize="14px"
                      text={countPaymentMethods}
                      classNameComponent="px-1 mt-1"
                    />
                  </div>
                </div>
                <div className="d-flex mt-2">
                  <div className="col-7">
                    <Button
                      fullWidth={true}
                      label={t('portalFinances')}
                      primary={true}
                      variant={'contained'}
                      onClick={() => {
                        window.open(
                          `https://billing.stripe.com/p/login/8wM01UcrC2ZDd8Y6oo?prefilled_email=${currentUser?.email}`,
                          '_blank'
                        );
                      }}
                    />
                  </div>
                  <div className="col-5 px-3">
                    <Button
                      fullWidth={true}
                      onClick={() => setDisplayInvoices(!displayInvoices)}
                      label={t('invoices')}
                      primary={true}
                      variant={'text'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Box>
          <Box width="40%">
            <div
              style={{
                backgroundColor: businessPreference?.mainColor + '40',
                height: '150px',
                borderRadius: '12px',
                overflow: 'hidden',
              }}
              className="row"
            >
              <div
                style={{
                  marginTop: '-10px',
                  padding: '40px',
                }}
                className="col-4"
              >
                {businessPreference?.formula?.toUpperCase() === 'FLASH' ? (
                  <FlashIcon
                    fill1={darkColor}
                    fill2={businessPreference?.mainColor}
                  />
                ) : businessPreference?.formula?.toUpperCase() === 'RISE' ? (
                  <RiseIcon
                    fill1={darkColor}
                    fill2={businessPreference?.mainColor}
                  />
                ) : (
                  <TrialIcon
                    fill1={darkColor}
                    fill2={businessPreference?.mainColor}
                  />
                )}
              </div>

              <div className="mt-4 px-2 col-5">
                <GeneralText
                  primary={false}
                  color={businessPreference?.mainColor + '70'}
                  size="bold"
                  fontSize="16px"
                  text={t('formula')}
                  classNameComponent="mt-2 px-1"
                />
                <div className="mt-2" style={{ position: 'relative' }}>
                  <div style={{ position: 'absolute', right: 5, bottom: -15 }}>
                    <GeneralText
                      primary={false}
                      color={businessPreference?.mainColor}
                      size="bold"
                      fontSize="18px"
                      type="status"
                      icon="RefreshOutlined"
                      classNameComponent="mt-5"
                      text={t(businessPreference?.frequency) || '-'}
                    />
                  </div>
                  <GeneralText
                    primary={false}
                    color={businessPreference?.mainColor}
                    size="bold"
                    classNameComponent="crimson-text-family"
                    fontSize="54px"
                    text={businessPreference?.formula?.toUpperCase()}
                  />
                </div>
              </div>
              <div className="col-3 mt-3 ">
                <div className="mb-3">
                  <GeneralText
                    primary={false}
                    color={businessPreference?.mainColor}
                    size="bold"
                    fontSize="18px"
                    type="status"
                    text={t('active')}
                  />
                  <div className="mt-3">
                    <GeneralText
                      primary={false}
                      color={businessPreference?.mainColor}
                      size="medium"
                      fontSize="11px"
                      text={'#' + businessPreference?.id?.slice(0, 8)}
                    />
                  </div>
                  <div>
                    <GeneralText
                      primary={false}
                      color={businessPreference?.mainColor}
                      size="medium"
                      fontSize="9px"
                      text={moment
                        .unix(
                          businessPreference?.timeStamp?.seconds ||
                            businessPreference?.timeStamp?._seconds
                        )
                        .format('MMM YYYY')}
                    />
                  </div>
                </div>
                <div className="d-flex hover">
                  <Button
                    fullWidth
                    noShadow
                    label={t('editPlan')}
                    primary={true}
                    color={mainColor}
                    size={'small'}
                    buttonSx={{ borderColor: mainColor }}
                    variant={'outlined'}
                    onClick={() => {}}
                  />
                </div>
              </div>
            </div>
          </Box>
        </Box>

        {displayInvoices && (
          <Box mt={4} p={2}>
            <GeneralText
              text={t('invoices')}
              primary={true}
              size="bold"
              fontSize="16px"
            />
            {lists?.length === 0 && (
              <GeneralText
                text={t('noInvoiceGenerated')}
                primary={true}
                size="regular"
                fontSize="12px"
                classNameComponent="mt-3"
              />
            )}
            <List dense>
              {lists?.length > 0 &&
                lists?.map((card, idx) => {
                  return (
                    <div key={idx}>
                      <ListItem divider>
                        <ListItemText sx={{ width: '35%' }}>
                          {card?.name}
                        </ListItemText>
                        <ListItemText sx={{ width: '15%' }}>
                          {moment
                            .unix(
                              card?.invoiceDate?.seconds ||
                                card?.invoiceDate?._seconds
                            )
                            .format('DD MMM YYYY')}
                        </ListItemText>
                        <ListItemText sx={{ width: '15%' }}>
                          {'# ' + card?.searchId}
                        </ListItemText>
                        <ListItemText sx={{ width: '20%' }}>
                          {card?.finances?.total?.toFixed(2) + ' $'}
                        </ListItemText>
                        <ListItemButton
                          sx={{
                            width: '15%',
                            fontWeight: 600,
                            color: 'secondary.main',
                          }}
                          onClick={() => {
                            handleOpenCard(card);
                          }}
                        >
                          {t('open')}
                        </ListItemButton>
                      </ListItem>
                    </div>
                  );
                })}
            </List>
          </Box>
        )}

        <Box display="flex" mt={4} p={2}>
          <Box width="30%">
            <GeneralText
              primary={true}
              size="bold"
              fontSize="16px"
              text={t('groups')}
              classNameComponent="mb-3"
            />
            <List>
              {businessPreference?.groups?.map((group) => (
                <ListItem
                  key={group?.identifiant}
                  button
                  dense
                  divider
                  sx={{
                    backgroundColor:
                      selectedGroup?.identifiant === group?.identifiant
                        ? isDarkMode
                          ? '#1a1a1a'
                          : '#F5F5F5'
                        : 'transparent',
                  }}
                  onClick={() => handleSelectGroup(group)}
                >
                  <ListItemText
                    primary={group?.name}
                    secondary={group?.description}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Box width="70%">
            {!isOpen && (
              <div className="px-4">
                <TextField
                  fullWidth
                  label={t('name')}
                  value={selectedGroup?.name}
                  onChange={(e) => {
                    setSelectedGroup({
                      ...selectedGroup,
                      name: e.target.value,
                    });
                    setChanges({
                      ...changes,
                      groups: true,
                    });
                  }}
                />
                <TextField
                  fullWidth
                  rows={2}
                  label={t('description')}
                  value={selectedGroup?.description}
                  onChange={(e) => {
                    setSelectedGroup({
                      ...selectedGroup,
                      description: e.target.value,
                    });
                    setChanges({
                      ...changes,
                      groups: true,
                    });
                  }}
                />
                <GeneralText
                  primary={true}
                  size="regular"
                  fontSize="12px"
                  text={
                    t('identifiant') + ': ' + selectedGroup?.identifiant || ''
                  }
                  classNameComponent="mb-2 mt-4 grey-text"
                />
              </div>
            )}
          </Box>
        </Box>
        <Divider component="div" sx={{ mt: 3 }} />
        <Box display="flex" p={2}>
          <Box width="100%">
            <GeneralText
              primary={true}
              size="bold"
              fontSize="16px"
              text={t('tags')}
              classNameComponent="mb-3 mt-5"
            />
            <Autocomplete
              multiple
              sx={{ marginTop: '20px' }}
              key={'tags'}
              options={tags || []}
              getOptionLabel={(option) => option?.label || ''}
              value={tags}
              onChange={(event, newValue) => {
                setTags(newValue);
                setChanges({
                  ...changes,
                  tags: true,
                });
              }}
              renderInput={(params) => (
                <TextFieldMUI
                  {...params}
                  onKeyPress={handleAddTag}
                  label={t('manageTags')}
                  variant="outlined"
                  sx={{
                    'MuiFormControl-root': {
                      width: '100%',
                    },
                    '.MuiInputBase-input': {
                      padding: '2px 10px 2px 10px',
                    },
                    '& .MuiFormLabel-root': {
                      backgroundColor: isDarkMode && 'rgb(51,51,51)',
                      padding: '2px 10px 2px 10px',
                      borderRadius: '10px',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                      '&.Mui-focused fieldset': {
                        borderColor: businessPreference?.mainColor || '#000',
                        boxShadow: `0 0 0 0.2rem ${
                          businessPreference?.mainColor + '20'
                        }`,
                      },
                    },
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Box>
        </Box>

        <Divider component="div" sx={{ mt: 3 }} />
        <Box display="flex" p={2}>
          <Box width="100%">
            <GeneralText
              primary={true}
              size="bold"
              fontSize="16px"
              text={t('preferences')}
              classNameComponent="mb-3 mt-5"
            />
            <div className="row">
              <div className="col-4 mt-1">
                <Checkbox
                  fieldType={'boolean'}
                  staticField={false}
                  defaultValue={false}
                  required={false}
                  label={t('allowNodeAccess')}
                  value={formData?.allowNodeAccess}
                  fullWidth
                  onBlur={(event, value) =>
                    handleSaveField('allowNodeAccess', event.target.checked)
                  }
                  onChange={(event, value) =>
                    handleSaveField('allowNodeAccess', event.target.checked)
                  }
                />
              </div>
              <div className="col-4 mt-1">
                <Checkbox
                  fieldType={'boolean'}
                  staticField={false}
                  defaultValue={false}
                  required={false}
                  label={t('devModeActivated')}
                  value={formData?.devMode}
                  fullWidth
                  onBlur={(event, value) =>
                    handleSaveField('devMode', event.target.checked)
                  }
                  onChange={(event, value) =>
                    handleSaveField('devMode', event.target.checked)
                  }
                />
              </div>
            </div>
          </Box>
        </Box>
        <Divider component="div" sx={{ mt: 3 }} />
        <Box display="flex" p={2}>
          <Box width="100%">
            <GeneralText
              primary={true}
              size="bold"
              fontSize="16px"
              text={t('security')}
              classNameComponent="mb-3 mt-5"
            />
            <div className="row">
              <div className="col-4 mt-1">
                <Checkbox
                  fieldType={'boolean'}
                  staticField={false}
                  defaultValue={false}
                  required={false}
                  label={t('ipRestriction')}
                  value={formData?.ipRestriction ? true : false}
                  fullWidth
                  onBlur={(event, value) =>
                    handleSaveField('ipRestriction', event.target.checked)
                  }
                  onChange={(event, value) =>
                    handleSaveField('ipRestriction', event.target.checked)
                  }
                />
              </div>

              {formData?.ipRestriction && (
                <div className="col-4 mt-1">
                  <TextField
                    fullWidth
                    type="password"
                    onBlur={async (event) => {
                      await handleSavePreferencesIP(event.target.value);
                    }}
                    label={t('ipAddress')}
                    value={formData?.ipAddress}
                    onChange={(e) => {
                      handleSaveField('ipAddress', e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
          </Box>
        </Box>
        <Divider component="div" sx={{ mt: 3 }} />
        <Box display="flex" p={2} mt={3}>
          <Box width="30%">
            <GeneralText
              primary={true}
              size="bold"
              fontSize="16px"
              text={t('links')}
              classNameComponent="mb-3 mt-5"
            />
          </Box>
          <Box width="35%">
            <div className="mt-2">
              <GeneralText
                primary={true}
                size="bold"
                fontSize="13px"
                text={t('entityIdCopy')}
                onClick={() => {
                  handleCopyEntity();
                }}
              />
            </div>
            <GeneralText
              primary={true}
              size="regular"
              fontSize="12px"
              text={t('entityIdCopyMsg')}
            />
          </Box>
          <Box width="35%">
            <div className="mt-2">
              <GeneralText
                primary={true}
                size="bold"
                fontSize="13px"
                text={t('dynamicEmployeeLink')}
                onClick={() => {
                  handleCopyLinkEmployee();
                }}
              />
            </div>
            <GeneralText
              primary={true}
              size="regular"
              fontSize="12px"
              text={t('dynamicEmployeeLinkMsg')}
            />
          </Box>
        </Box>
      </Block>
    </MainLayoutV2>
  );
};

export default Settings;
