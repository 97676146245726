import React from 'react';

const RiseIcon = ({ fill1 = '#200ef0', fill2 = '#200ea7', ...props }) => (
  <svg
    id="Calque_2"
    data-name="Calque 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 89.5 89.5"
    {...props}
  >
    <defs>
      <style>
        {`
          .cls-1 { fill: ${fill1}; stroke:none;}
          .cls-2 { fill: ${fill2}; stroke:none;}
        `}
      </style>
    </defs>
    <g id="Calque_1-2" data-name="Calque 1">
      <g>
        <path
          className="cls-1"
          d="M68.8,47.27c-15.6,0-28.25-12.65-28.25-28.25,0-7.33,2.79-14,7.36-19.02H15.92C7.13,0,0,7.13,0,15.92v35.83h30.72c4.82,0,8.72,3.9,8.72,8.72v29.04h34.15c8.79,0,15.92-7.13,15.92-15.92v-35.35c-5.16,5.55-12.52,9.03-20.7,9.03Z"
        />
        <path
          className="cls-2"
          d="M68.8,47.27c8.18,0,15.54-3.48,20.7-9.03V15.92c0-8.79-7.13-15.92-15.92-15.92h-25.67c-4.57,5.02-7.36,11.69-7.36,19.02,0,15.6,12.65,28.25,28.25,28.25Z"
        />
        <path
          className="cls-2"
          d="M30.72,51.74H0v21.84c0,8.79,7.13,15.92,15.92,15.92h23.52v-29.04c0-4.82-3.9-8.72-8.72-8.72Z"
        />
      </g>
    </g>
  </svg>
);

export default RiseIcon;
