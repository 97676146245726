import React, { useState, useEffect, useCallback } from 'react';
import chroma from 'chroma-js';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import * as modalActions from '../redux/actions/modal-actions.js';
import { useTranslation } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Lottie from 'react-lottie';
import animationData from '../lotties/empty-box';
import { DndProvider } from 'react-dnd';
import {
  setCurrentUser,
  setGeneralStatus,
} from '../redux/actions-v2/coreAction.js';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import NodeAIIcon from '../components/@generalComponents/layout/NodeAIIcon.jsx';
import * as sidebarActions from '../redux/actions/sidebar-actions.js';
import { Divider, ListItemText, Tooltip } from '@mui/material';
import AllModalsRoot from '../modals/index.jsx';
import AllSidebarsRoot from '../sidebars/index.jsx';
import AllDrawersRoot from '../drawers/index.jsx';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import PagesNav from '../components/@generalComponents/layout/PagesNav.jsx';

import '../components/@generalComponents/layout/layout.css';
import {
  clearBusinessData,
  fetchBusinessData,
  pageLoaded,
  setCurrentSection,
  setOnCall,
} from '../redux/actions-v2/coreAction.js';
import { clearAllLists } from '../redux/actions-v2/listAction.js';
import DialogWindow from '../stories/general-components/DialogWindow.jsx';
import LogRocket from 'logrocket';
import TopNavBar from './components/TopNavBar.jsx';
import { QrCodeScannerOutlined } from '@mui/icons-material';
import UserSecMenu from '../components/@generalComponents/layout/UserSecMenu.jsx';
import Blocks from '../stories/layout-components/Block.jsx';
import Button from '../stories/general-components/Button.jsx';
import NodeBOS from '../components/NodeBOS.jsx';
import NodeConnect from '../components/NodeConnect.jsx';
import NodeAI from '../components/NodeAI.jsx';
import nodeAxiosFirebase from '../utils/nodeAxiosFirebase.js';
import NodeInsights from '../components/NodeInsights.jsx';

const MainLayoutV2 = ({
  children,
  pageTitle,
  tabs,
  actions,
  sectionTitle,
  formatedPath,
  elementId,
  searchActivated,
  icon,
  manualIndex,
  usersInRoom,
  error404,
  subTitle,
  hideMenu,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const currentLocation = useLocation();
  const currentPath = currentLocation.pathname;

  const { moduleId } = useParams();

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hoveredLogo, setHoveredLogo] = useState(null);
  const businessesStorage = sessionStorage.getItem('businesses');
  const businesses = JSON.parse(businessesStorage);

  const [taskOpen, setTaskOpen] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentStatusMain, setCurrentStatusMain] = useState({
    status: '',
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const isDarkMode = theme.palette.mode === 'dark';

  const softwareVersion = process.env.REACT_APP_VERSION;

  const presences = useSelector((state) => state.core.onCall);
  const businessPreference = useSelector((state) => state.core.businessData);
  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );
  const currentUser = useSelector((state) => state.core.user);
  const businessModules = businessStructure?.modules;
  const currentStatus = useSelector((state) => state.core.status);

  const mainColor = businessPreference?.mainColor || '#000000';
  const secColor = businessPreference?.secColor || '#000000';
  const lightGreyScale = chroma
    .scale([mainColor, '#f2f2f2'])
    .mode('lab')
    .colors(10)
    .map((color) => chroma(color).brighten(1.4));

  const lightGreyScaleSec = chroma
    .scale([secColor, '#f2f2f2'])
    .mode('lab')
    .colors(10)
    .map((color) => chroma(color).brighten(1.4));

  const openAIModal = () => {
    dispatch(
      sidebarActions.nodeAiSidebar({
        show: true,
        isOpen: true,
      })
    );
  };

  useEffect(() => {
    if (currentStatus?.status === 'loading') {
      setCurrentStatusMain('loading');
      setTimeout(() => {
        setCurrentStatusMain('');
      }, 2200);
    } else if (currentStatus?.status === 'error') {
      setCurrentStatusMain('error');
      setTimeout(() => {
        setCurrentStatusMain('');
      }, 1000);
    } else if (currentStatus?.status === 'success') {
      setCurrentStatusMain('success');
      setTimeout(() => {
        setCurrentStatusMain('');
      }, 2800);
    } else {
      setCurrentStatusMain('');
    }
  }, [currentStatus?.status]);

  const handleNavigateDash = useCallback((section) => {
    dispatch(setCurrentSection('OPERATIONS'));
    navigate('/app/dashboard');
  }, []);

  const handleNavigateAI = () => {
    dispatch(setCurrentSection('AI'));
    navigate('/ai/chat');
  };

  const handleNavigateInsights = () => {
    dispatch(setCurrentSection('INSIGHTS'));
    navigate('/insights');
  };

  const handleNavigateConnect = () => {
    dispatch(setCurrentSection('CONNECT'));
    navigate('/connect/integrations');
  };

  if (
    window.location.hostname !== 'localhost' &&
    window.location.port !== '3000'
  ) {
    LogRocket.init('hpp7xp/node');

    if (currentUser) {
      LogRocket.identify(currentUser?.uid, {
        name: currentUser?.displayName,
        email: currentUser?.email,
        subscriptionType: businessPreference?.formula,
      });
    }
  }

  const openModalScan = () => {
    dispatch(
      modalActions.modalScan({
        isOpen: true,
      })
    );
  };

  const actionsSpeed = [
    {
      name: t('scan'),
      icon: <QrCodeScannerOutlined />,
      onClick: openModalScan,
    },
  ];

  if (businessPreference?.formula?.toUpperCase() === 'RISE') {
    actionsSpeed.push({
      name: t('nodeAI'),
      icon: <NodeAIIcon size={26} />,
      onClick: openAIModal,
    });
  }

  const updatePosition = async () => {
    dispatch(
      setGeneralStatus({
        status: 'loading',
        position: 'position',
        type: 'pulse',
      })
    );

    try {
      const users = Object.entries(presences).reduce(
        (acc, [userId, dataId]) => {
          if (dataId === businessPreference?.id) {
            acc[userId] = dataId;
          }
          return acc;
        },
        {}
      );

      if (users[currentUser?.uid] === businessPreference?.id) {
        return;
      }

      const positionData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/position`,
        body: {
          pathId: businessPreference?.id,
          action: 'add',
        },
      });

      dispatch(
        setGeneralStatus({
          status: 'success',
          position: 'position',
          type: 'pulse',
        })
      );
    } catch (error) {
      console.error('Error position:', error);
    }
  };

  useEffect(() => {
    if (moduleId) {
      updatePosition();
    }
  }, [moduleId]);

  const handleBusinessSelect = (business) => {
    localStorage.setItem('section', 'OPERATIONS');
    dispatch(setCurrentSection('OPERATIONS'));
    dispatch(clearAllLists());
    const userData = {
      uid: currentUser?.uid,
      email: currentUser?.email || '',
      displayName: currentUser?.displayName || '',
      photoURL: currentUser?.photoURL || '',
    };
    dispatch(setCurrentUser(userData, business?.businessId));
    sessionStorage.setItem('businessToken', business?.token);
    localStorage.setItem('businessId', business?.businessId);
    localStorage.setItem('businessName', business?.name);
    dispatch(fetchBusinessData(business?.businessId, t, currentLanguage));
    setDialogOpen(false);
    navigate('/app/dashboard');
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const handleChangeBusiness = () => {
    setDialogOpen(true);
  };

  const openSideTasksAgenda = () => {
    setTaskOpen(!taskOpen);
  };

  const getModuleName = () => {
    const module = businessModules?.find((m) => m.id === moduleId);

    if (pageTitle) {
      return pageTitle || '';
    }

    return module?.name || '';
  };

  useEffect(() => {
    if (businessModules) {
      setTimeout(() => {
        dispatch(pageLoaded(true));
      }, 3000);
    }
  }, [businessModules]);

  return (
    <div>
      <DndProvider backend={HTML5Backend}>
        <DialogWindow
          title={t('switchAccount')}
          open={dialogOpen}
          size={'medium'}
          width={'medium'}
          onClose={() => setDialogOpen(false)}
        >
          <div>
            <div className="row">
              {businesses &&
                businesses?.map((business, idx) => (
                  <div
                    key={idx}
                    className="col-3 hover align-c p-1"
                    onClick={() => handleBusinessSelect(business)}
                  >
                    <div>
                      <img
                        style={{ width: '30px', height: '30px' }}
                        src={`https://storage.googleapis.com/node-business-logos/${business?.businessId}.png`}
                      />
                    </div>
                    <ListItemText
                      primary={business?.name}
                      primaryTypographyProps={{
                        fontWeight: 500,
                        color: isDarkMode ? '#FFF' : '#000',
                        fontSize: '10px',
                      }}
                      secondary={business?.city || '-'}
                      secondaryTypographyProps={{
                        variant: 'caption',
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </DialogWindow>

        <I18nextProvider i18n={i18n}>
          <Helmet>
            <title>
              {`Node -  ${getModuleName(pageTitle, currentLanguage)}`}
            </title>
            <meta name="robots" content="noindex" />
          </Helmet>
          <AllModalsRoot />
          {businessPreference?.id && (
            <div
              style={{
                backgroundColor: isDarkMode
                  ? 'rgba(0, 0, 0, 0.82)'
                  : 'rgba(255, 255, 255, 0.57)',
                height: '100vh',
                overflow: 'hidden',
                position: 'relative',
              }}
            >
              <div
                style={{
                  width: '900px',
                  height: '400px',
                  overflow: 'hidden',
                  backgroundColor: lightGreyScale[0] + '60',
                  borderRadius: '50%',
                  filter: 'blur(120px)',
                  position: 'absolute',
                  zIndex: 1,
                  top: '220px',
                  left: '-420px',
                }}
              />
              <div
                style={{
                  width: '1400px',
                  height: '900px',
                  overflow: 'hidden',
                  backgroundColor: lightGreyScaleSec[2] + '50',
                  borderRadius: '50%',
                  filter: 'blur(120px)',
                  position: 'absolute',
                  zIndex: 1,
                  top: '-120px',
                  right: '-310px',
                }}
              />
              <div
                style={{
                  width: '500px',
                  height: '600px',
                  overflow: 'hidden',
                  backgroundColor: isDarkMode ? '#00000040' : '#FFFFFF',
                  rotate: '325deg',
                  borderRadius: '80px',
                  filter: 'blur(120px)',
                  position: 'absolute',
                  zIndex: 1,
                  bottom: '-190px',
                  left: '-220px',
                }}
              />
              <div
                style={{
                  width: '1200px',
                  height: '1200px',
                  overflow: 'hidden',
                  backgroundColor: lightGreyScale[0] + '60',
                  borderRadius: '50%',
                  filter: 'blur(120px)',
                  position: 'absolute',
                  zIndex: 0,
                  bottom: '-80px',
                  left: '-80px',
                }}
              />
              <div
                style={{
                  width: '900px',
                  height: '900px',
                  overflow: 'hidden',
                  borderRadius: '50%',
                  filter: 'blur(120px)',
                  position: 'absolute',
                  zIndex: 0,
                  top: '-220px',
                  left: '-120px',
                }}
              />
              <Grid style={{ height: '100vh' }} container>
                <Grid
                  sx={{
                    overflow: 'hidden',
                  }}
                  container
                >
                  <Grid
                    xs={0.4}
                    sm={0.4}
                    md={0.4}
                    lg={0.4}
                    xl={0.4}
                    item
                    id="side-content"
                    sx={{
                      height: '100vh',
                      maxHeight: '100vh',
                      position: 'relative',
                      zIndex: 10,
                    }}
                  >
                    <div
                      style={{ height: '50vh', position: 'relative' }}
                      className="hover"
                    >
                      <div>
                        <div
                          className="mb-4 p-3 mt-2"
                          style={{
                            height: '60px',
                            zIndex: 1000,
                            minHeight: '55px',
                            position: 'relative',
                          }}
                        >
                          <div
                            className="middle-content"
                            style={{
                              position: 'relative',
                              height: isTablet
                                ? currentStatusMain === 'loading' ||
                                  currentStatusMain === 'success' ||
                                  currentStatusMain === 'error'
                                  ? '40px'
                                  : '35px'
                                : currentStatusMain === 'loading' ||
                                  currentStatusMain === 'success' ||
                                  currentStatusMain === 'error'
                                ? '50px'
                                : '45px',
                              width: isTablet
                                ? currentStatusMain === 'loading' ||
                                  currentStatusMain === 'success' ||
                                  currentStatusMain === 'error'
                                  ? '40px'
                                  : '35px'
                                : currentStatusMain === 'loading' ||
                                  currentStatusMain === 'success' ||
                                  currentStatusMain === 'error'
                                ? '50px'
                                : '45px',
                            }}
                          >
                            <img
                              src={`https://storage.googleapis.com/node-business-logos/${businessPreference?.id}.png`}
                              height={isTablet ? '35px' : '45px'}
                              width={isTablet ? '35px' : '45px'}
                              style={{
                                borderRadius: '50%',
                                position: 'absolute',
                                zIndex: 100,
                                marginLeft: '2.8px',
                                marginTop: '2.8px',
                              }}
                              className="hover"
                              onClick={handleChangeBusiness}
                            />
                            <div
                              style={{
                                borderRadius: '50%',
                                position: 'absolute',
                                zIndex: 90,
                                backgroundColor:
                                  currentStatusMain === 'loading'
                                    ? businessPreference?.mainColor + '70'
                                    : currentStatusMain === 'error'
                                    ? '#e3292970'
                                    : currentStatusMain === 'success'
                                    ? '#c2de2370'
                                    : 'transparent',
                                height: isTablet
                                  ? currentStatusMain === 'loading' ||
                                    currentStatusMain === 'success' ||
                                    currentStatusMain === 'error'
                                    ? '40px'
                                    : '35px'
                                  : currentStatusMain === 'loading' ||
                                    currentStatusMain === 'success' ||
                                    currentStatusMain === 'error'
                                  ? '50px'
                                  : '45px',
                                width: isTablet
                                  ? currentStatusMain === 'loading' ||
                                    currentStatusMain === 'success' ||
                                    currentStatusMain === 'error'
                                    ? '40px'
                                    : '35px'
                                  : currentStatusMain === 'loading' ||
                                    currentStatusMain === 'success' ||
                                    currentStatusMain === 'error'
                                  ? '50px'
                                  : '45px',
                              }}
                              className={
                                currentStatusMain === 'loading'
                                  ? 'pulse-loading'
                                  : ''
                              }
                            ></div>
                          </div>
                        </div>

                        <Divider component="div" sx={{ marginLeft: '10px' }} />
                      </div>
                      <div
                        style={{ position: 'relative', marginRight: '45px' }}
                        className="mt-3 align-right"
                      >
                        {businessPreference?.formula &&
                          currentUser?.activeBusiness?.modules?.bos !==
                            false && (
                            <div
                              style={{ position: 'relative', height: '65px' }}
                              className="d-flex"
                            >
                              <div style={{ position: 'absolute' }}>
                                <img
                                  style={{
                                    opacity:
                                      currentPath?.startsWith(
                                        '/app/operations'
                                      ) ||
                                      currentPath?.startsWith(
                                        '/app/dashboard'
                                      ) ||
                                      currentPath?.startsWith(
                                        '/app/marketing'
                                      ) ||
                                      currentPath?.startsWith('/app/nodes') ||
                                      currentPath?.startsWith('/app/inbox') ||
                                      currentPath?.startsWith('/app/element') ||
                                      currentPath?.startsWith('/app/finances')
                                        ? 1
                                        : 0.65,
                                  }}
                                  src={`/assets/v3/components/menu${
                                    isDarkMode ? '-dark' : ''
                                  }.svg`}
                                  width={'65px'}
                                  height={'65px'}
                                  alt="Menu"
                                />
                              </div>
                              <Tooltip title={t('nodeBOS')}>
                                <div
                                  onClick={handleNavigateDash}
                                  style={{
                                    position: 'absolute',
                                    width: '45px',
                                    height: '45px',
                                    borderRadius: '50% 0% 0% 50%',
                                    marginBottom: '15px',
                                    padding: '5px',
                                  }}
                                >
                                  <div
                                    onMouseEnter={() => setHoveredLogo('BOS')}
                                    onMouseLeave={() => setHoveredLogo(null)}
                                    style={{
                                      marginTop: '14px',
                                      marginLeft: '6px',
                                      width: '28px',
                                      height: '28px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <NodeBOS
                                      primaryColor={
                                        hoveredLogo === 'BOS' ||
                                        currentPath?.startsWith('/app/')
                                          ? businessPreference?.mainColor
                                          : businessPreference?.mainColor + '99'
                                      }
                                      secondaryColor={
                                        businessPreference?.secColor
                                      }
                                    />
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          )}
                        {businessPreference?.modules?.includes('insights') &&
                          currentUser?.activeBusiness?.modules?.insights !==
                            false && (
                            <div
                              style={{ position: 'relative', height: '65px' }}
                              className="d-flex"
                            >
                              <div style={{ position: 'absolute' }}>
                                <img
                                  src={`/assets/v3/components/menu${
                                    isDarkMode ? '-dark' : ''
                                  }.svg`}
                                  style={{
                                    opacity: currentPath?.startsWith(
                                      '/insights'
                                    )
                                      ? 1
                                      : 0.65,
                                  }}
                                  height={'65px'}
                                  alt="Menu"
                                />
                              </div>
                              <Tooltip title={t('nodeInsights')}>
                                <div
                                  onClick={handleNavigateInsights}
                                  style={{
                                    position: 'absolute',
                                    width: '45px',
                                    height: '45px',
                                    borderRadius: '50% 0% 0% 50%',
                                    marginBottom: '15px',
                                    padding: '5px',
                                  }}
                                >
                                  <div
                                    onMouseEnter={() =>
                                      setHoveredLogo('INSIGHTS')
                                    }
                                    onMouseLeave={() => setHoveredLogo(null)}
                                    style={{
                                      marginTop: '17px',
                                      marginLeft: '11px',
                                      width: '22px',
                                      height: '22px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <NodeInsights
                                      primaryColor={
                                        hoveredLogo === 'INSIGHTS' ||
                                        currentPath?.startsWith('/insights')
                                          ? businessPreference?.mainColor
                                          : businessPreference?.mainColor + '99'
                                      }
                                      secondaryColor={
                                        businessPreference?.secColor
                                      }
                                    />
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          )}
                        {businessPreference?.formula?.toUpperCase() ===
                          'RISE' &&
                          currentUser?.activeBusiness?.modules?.connect !==
                            false && (
                            <div
                              style={{ position: 'relative', height: '65px' }}
                              className="d-flex"
                            >
                              <div style={{ position: 'absolute' }}>
                                <img
                                  src={`/assets/v3/components/menu${
                                    isDarkMode ? '-dark' : ''
                                  }.svg`}
                                  width={'65px'}
                                  style={{
                                    opacity: currentPath?.startsWith(
                                      '/connect/'
                                    )
                                      ? 1
                                      : 0.65,
                                  }}
                                  height={'65px'}
                                  alt="Menu"
                                />
                              </div>
                              <Tooltip title={t('nodeConnect')}>
                                <div
                                  onClick={handleNavigateConnect}
                                  style={{
                                    position: 'absolute',
                                    width: '45px',
                                    height: '45px',
                                    borderRadius: '50% 0% 0% 50%',
                                    marginBottom: '15px',
                                    padding: '5px',
                                  }}
                                >
                                  {' '}
                                  <div
                                    onMouseEnter={() =>
                                      setHoveredLogo('CONNECT')
                                    }
                                    onMouseLeave={() => setHoveredLogo(null)}
                                    style={{
                                      marginTop: '17px',
                                      marginLeft: '10px',
                                      width: '31px',
                                      height: '31px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <NodeConnect
                                      primaryColor={
                                        hoveredLogo === 'CONNECT' ||
                                        currentPath?.startsWith('/connect/')
                                          ? businessPreference?.mainColor
                                          : businessPreference?.mainColor + '99'
                                      }
                                      secondaryColor={
                                        businessPreference?.secColor
                                      }
                                    />
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          )}
                        {businessPreference?.formula?.toUpperCase() ===
                          'RISE' &&
                          currentUser?.activeBusiness?.modules?.ai !==
                            false && (
                            <div
                              style={{ position: 'relative', height: '65px' }}
                              className="d-flex"
                            >
                              <div style={{ position: 'absolute' }}>
                                <img
                                  src={`/assets/v3/components/menu${
                                    isDarkMode ? '-dark' : ''
                                  }.svg`}
                                  width={'65px'}
                                  style={{
                                    opacity: currentPath?.startsWith('/ai/')
                                      ? 1
                                      : 0.65,
                                  }}
                                  height={'65px'}
                                  alt="Menu"
                                />
                              </div>
                              <Tooltip title={t('nodeAI')}>
                                <div
                                  onClick={handleNavigateAI}
                                  style={{
                                    position: 'absolute',
                                    width: '45px',
                                    height: '45px',
                                    borderRadius: '50% 0% 0% 50%',
                                    marginBottom: '15px',
                                    padding: '5px',
                                  }}
                                >
                                  <div
                                    onMouseEnter={() => setHoveredLogo('AI')}
                                    onMouseLeave={() => setHoveredLogo(null)}
                                    style={{
                                      marginTop: '15px',
                                      marginLeft: '10px',
                                      width: '25px',
                                      height: '25px',
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <NodeAI
                                      strokeColor={isDarkMode ? '#000' : '#FFF'}
                                      primaryColor={
                                        hoveredLogo === 'AI' ||
                                        currentPath?.startsWith('/ai/')
                                          ? businessPreference?.mainColor
                                          : businessPreference?.mainColor + '99'
                                      }
                                      secondaryColor={
                                        businessPreference?.secColor
                                      }
                                    />
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                          )}
                      </div>
                    </div>
                    <div style={{ height: isTablet ? '10vh' : '20vh' }}></div>
                    <div
                      style={{
                        height: isTablet ? '25vh' : '15vh',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: isTablet ? '13px' : '20px',
                      }}
                    >
                      <UserSecMenu />
                    </div>
                  </Grid>

                  <Grid
                    sx={{
                      padding: '2vh 0vh 2vh 2vh',
                      height: '96vh',
                      maxHeight: '96vh',
                      position: 'relative',
                      zIndex: 10,
                    }}
                    xs={2.15}
                    sm={0.95}
                    md={2.15}
                    lg={2.15}
                    xl={2.15}
                    item
                  >
                    <Paper
                      className="glassmorphism"
                      sx={{
                        borderRadius: '12px 0px 0px 12px',
                        border: '1.5px solid',
                        borderColor: isDarkMode ? '#000000' : '#FFFFFF',
                        backgroundColor: isDarkMode
                          ? 'rgba(0, 0, 0, 0.55)'
                          : 'rgba(255, 255, 255, 0.55)',
                        padding: '10px',
                      }}
                      elevation={0}
                    >
                      <div
                        style={{
                          borderRadius: '12px',
                          backgroundColor: isDarkMode
                            ? 'rgba(0, 0, 0, 0.55)'
                            : businessPreference?.mainColor + '30' ||
                              'rgba(255, 255, 255, 0.70)',
                        }}
                      >
                        <div
                          style={{
                            height: '88vh',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            position: 'relative',
                            marginRight: '-5px',
                            zIndex: 1000,
                          }}
                        >
                          <PagesNav
                            isTablet={isTablet}
                            tasksOpen={taskOpen}
                            openSideTasksAgenda={openSideTasksAgenda}
                            drawerOpen={drawerOpen}
                            setDrawerOpen={setDrawerOpen}
                          />
                        </div>
                        <div
                          style={{
                            position: 'relative',
                            height: '6vh',
                            paddingLeft: '20px',
                            overflow: 'hidden',
                            paddingTop: '20px',
                          }}
                        >
                          <Typography
                            variant="caption"
                            fontSize={isTablet ? '8px' : '9px'}
                            color={isDarkMode ? '#FFFFFF85' : '#00000085'}
                            lineHeight="1"
                            display="block"
                            fontWeight={600}
                            gutterBottom
                          >
                            {isTablet ? 'Node™' : 'Intelligence Node Canada™'}
                          </Typography>
                          <Typography
                            variant="caption"
                            fontSize={isTablet ? '6.5px' : '6px'}
                            color={isDarkMode ? '#FFFFFF85' : '#00000085'}
                            lineHeight="1"
                            display="block"
                            gutterBottom
                          >
                            Version {softwareVersion} - Tous droits réservés.
                          </Typography>
                        </div>
                      </div>
                    </Paper>
                  </Grid>

                  <Grid
                    xs={9.4}
                    sm={10.6}
                    md={9.4}
                    lg={9.4}
                    xl={9.4}
                    item
                    id="main-content"
                    sx={{
                      padding: '2vh 0vh 2vh 0vh',
                      height: '96vh',
                      maxHeight: '96vh',
                      position: 'relative',
                      zIndex: 10,
                    }}
                  >
                    <Paper
                      className="glassmorphism"
                      sx={{
                        border: '1.5px solid',
                        borderColor: isDarkMode ? '#000000' : '#FFFFFF',
                        marginLeft: '-2px',
                        borderRadius: '0px 12px 12px 0px',
                        backgroundColor: isDarkMode
                          ? 'rgba(0, 0, 0, 0.55)'
                          : 'rgba(255, 255, 255, 0.55)',
                      }}
                      elevation={0}
                    >
                      <div>
                        <TopNavBar
                          isTablet={isTablet}
                          isMobile={isMobile}
                          pageTitle={pageTitle}
                          searchActivated={searchActivated}
                          actions={actions}
                          iconMain={icon}
                          elementId={elementId}
                          formatedPath={formatedPath}
                          usersInRoom={usersInRoom}
                          manualIndex={manualIndex}
                          tabs={tabs}
                          sectionTitle={sectionTitle}
                          subTitle={subTitle}
                        />

                        <AllSidebarsRoot />
                        <AllDrawersRoot />

                        <div
                          style={{
                            overflow: 'hidden',
                          }}
                        >
                          {!error404 ? (
                            <div style={{ height: '90vh' }}>{children}</div>
                          ) : (
                            <div>
                              <Blocks heightPercentage={98}>
                                <div className="middle-content align-c mt-5">
                                  <div className="mt-5">
                                    <Lottie
                                      options={defaultOptions}
                                      height={80}
                                      width={100}
                                    />
                                    <div className="middle-content mt-3">
                                      <Typography
                                        variant="h5"
                                        fontSize={'24px'}
                                        lineHeight="1.2"
                                        display="block"
                                        fontWeight={600}
                                        gutterBottom
                                      >
                                        {t('error404')}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        fontSize={'14px'}
                                        lineHeight="1.2"
                                        display="block"
                                        fontWeight={400}
                                        gutterBottom
                                      >
                                        {t('error404Desc')}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div className="mt-4">
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      label={t('navigateBack')}
                                      onClick={handleNavigateBack}
                                    />
                                  </div>
                                </div>
                              </Blocks>
                            </div>
                          )}
                        </div>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
        </I18nextProvider>
      </DndProvider>
    </div>
  );
};

export default MainLayoutV2;
