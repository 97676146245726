import React, { useState, useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';

const ButtonComponent = ({
  tags = [],
  comp,
  col,
  value,
  handleDataChange,
  layoutId,
  layoutIndex,
  selectedSettings,
  width,
  height,
  alignement,
  backgroundColor,
  color,
}) => {
  return (
    <div
      style={{
        borderRadius: '4px',
        height: '100%',
        width: '100%',
        alignContent: alignement,
        display: 'flex',
        justifyContent: alignement,
      }}
    >
      <button
        key={comp + col + layoutId}
        button
        style={{
          width: Number(width),
          height: Number(height),
          border: 'none',
          borderRadius: '8px',
          fontWeight: 600,
          padding: '10px',
          cursor: 'pointer',
          backgroundColor: backgroundColor,
          color: color,
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {value}
      </button>
    </div>
  );
};

export default ButtonComponent;
