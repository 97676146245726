const SecCardItems = ({
  backgroundColor = '#d3d3d3', // Default background color
  color = '#e21e1e', // Default primary fill color
  secondaryColor = '#efefef', // Default secondary fill color
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 180.15 134.35"
    id="Calque_2"
  >
    <defs>
      <style>
        {`
            .cls-1 { fill: ${secondaryColor}; }
            .cls-2 { fill: ${backgroundColor}; }
            .cls-3 { fill: ${color}; }
          `}
      </style>
    </defs>
    <g id="Calque_1-2">
      <rect
        className="cls-2"
        x=".73"
        width="179.42"
        height="39.88"
        rx="8.55"
        ry="8.55"
      />
      <rect
        className="cls-3"
        x="13.57"
        y="9.3"
        width="79.95"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        className="cls-3"
        x="96.01"
        y="9.42"
        width="36.4"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        className="cls-1"
        x="135.34"
        y="9.47"
        width="32.78"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        className="cls-1"
        x="13.32"
        y="21.54"
        width="154.8"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        className="cls-2"
        x=".73"
        y="47.48"
        width="179.42"
        height="39.4"
        rx="8.55"
        ry="8.55"
      />
      <rect
        className="cls-1"
        x="13.57"
        y="56.78"
        width="79.95"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        className="cls-1"
        x="96.01"
        y="56.89"
        width="36.4"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        className="cls-1"
        x="135.34"
        y="56.95"
        width="32.78"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        className="cls-1"
        x="13.32"
        y="69.02"
        width="154.8"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        className="cls-2"
        y="94.95"
        width="179.42"
        height="39.4"
        rx="8.55"
        ry="8.55"
      />
      <rect
        className="cls-1"
        x="11.84"
        y="104.25"
        width="79.95"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        className="cls-1"
        x="94.28"
        y="104.37"
        width="36.4"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        className="cls-1"
        x="133.61"
        y="104.42"
        width="32.78"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        className="cls-1"
        x="11.59"
        y="116.49"
        width="154.8"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
    </g>
  </svg>
);

export default SecCardItems;
