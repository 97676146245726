import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '../stories/general-components/TextField';
import DrawerSide from '../stories/layout-components/DrawerSide';
import nodeAxiosFirebase from '../utils/nodeAxiosFirebase';
import Checkbox from '../stories/general-components/Checkbox';
import { fetchBusinessData } from '../redux/actions-v2/coreAction';

const LocationSidebar = ({ closeSidebar, locationId, isDrawerOpen }) => {
  const { t, i18n } = useTranslation();
  const currentLangCode = i18n.language;
  const dispatch = useDispatch();

  const [selectedLocation, setSelectedLocation] = useState({});

  const businessPreference = useSelector((state) => state.core.businessData);
  const currentLocation = businessPreference?.locations?.find(
    (location) => location?.id === locationId
  );

  useEffect(() => {
    if (currentLocation) {
      setSelectedLocation(currentLocation);
    } else {
      setSelectedLocation({
        name: '',
        address: '',
        note: '',
        order: businessPreference?.locations?.length,
      });
    }
  }, [currentLocation]);

  const handleSave = async () => {
    try {
      await nodeAxiosFirebase({
        t,
        method: 'PATCH',
        url: `business/general`,
        showLoading: true,
        body: {
          type: 'location',
          lang: currentLangCode,
          locationData: selectedLocation,
        },
      });

      closeSidebar();
      dispatch(fetchBusinessData(businessPreference?.id, t));
    } catch (error) {
      console.error('Error saving fields:', error);
    }
  };

  return (
    <DrawerSide
      title={currentLocation?.name || t('resource')}
      handleDrawerClose={closeSidebar}
      isDrawerOpen={isDrawerOpen}
      handleSave={handleSave}
      isCreation={true}
    >
      <div>
        <TextField
          fullWidth
          type="text"
          label={t('name')}
          value={selectedLocation?.name}
          onChange={(e) =>
            setSelectedLocation({
              ...selectedLocation,
              name: e.target.value,
            })
          }
        />

        <TextField
          fullWidth
          label={t('address')}
          type="geo"
          value={selectedLocation?.address}
          onChange={(e) =>
            setSelectedLocation({
              ...selectedLocation,
              address: e.target.value,
            })
          }
        />
        <div className="col-12">
          <TextField
            fullWidth
            label={t('note')}
            type="text"
            value={selectedLocation?.note}
            onChange={(e) =>
              setSelectedLocation({
                ...selectedLocation,
                note: e.target.value,
              })
            }
          />
        </div>
        <div className="d-flex">
          <div className="col-8" style={{ paddingRight: '20px' }}>
            <TextField
              fullWidth
              label={t('order')}
              type="number"
              value={selectedLocation?.order}
              onChange={(e) =>
                setSelectedLocation({
                  ...selectedLocation,
                  order: e.target.value,
                })
              }
            />
          </div>
          <div className="col-4 mt-1">
            <Checkbox
              value={selectedLocation?.isActive ?? true}
              label={t('isActive')}
              onChange={(e) =>
                setSelectedLocation({
                  ...selectedLocation,
                  isActive: e.target.checked,
                })
              }
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
        </div>
      </div>
    </DrawerSide>
  );
};

export default LocationSidebar;
