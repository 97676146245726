import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Handle, Position } from '@xyflow/react';
import * as sidebarActions from '../../redux/actions/sidebar-actions';
import GeneralText from '../../stories/general-components/GeneralText';

function TextUpdaterNode({ data, isConnectable }) {
  const dispatch = useDispatch();

  const handleSettingsStructure = (structureId) => {
    if (structureId[0]) {
      dispatch(
        sidebarActions.structureSidebar({
          show: true,
          structureId: structureId[0],
          closeSidebar: handleCloseStructureSidebar,
          isDrawerOpen: true,
        })
      );
    }
  };

  const handleCloseStructureSidebar = () => {
    dispatch(
      sidebarActions.structureSidebar({
        show: false,
        structureId: null,
        closeSidebar: null,
        isDrawerOpen: false,
      })
    );
  };

  return (
    <div
      style={{
        width: 60,
        height: 60,
        borderRadius: '50%',
        background: data?.color,
        color: '#fff',
        fontWeight: 500,
        display: 'flex',
        fontSize: '8px',
        border: 'none',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={() => handleSettingsStructure(data?.structureId)}
    >
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
      />
      <div className="p-3">
        <GeneralText
          text={data?.label}
          size="medium"
          fontSize="6px"
          primary={false}
        />
        <div>
          <GeneralText
            text={data?.sublabel}
            size="medium"
            fontSize="5px"
            primary={false}
          />
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        isConnectable={isConnectable}
      />
    </div>
  );
}

export default TextUpdaterNode;
