import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import TextFieldMUI from '@mui/material/TextField';
import {
  IconButton,
  Box,
  Tab,
  Tabs,
  Menu,
  MenuItem,
  Tooltip,
  FormControl,
  InputLabel,
  List,
  ListItem,
} from '@mui/material';
import Button from '../../stories/general-components/Button';
import CheckboxMUI from '@mui/material/Checkbox';
import Checkbox from '../../stories/general-components/Checkbox';
import CancelIcon from '@mui/icons-material/Cancel';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CategoryIcon from '@mui/icons-material/Category';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import InventoryIcon from '@mui/icons-material/Inventory';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TaskIcon from '@mui/icons-material/Task';
import InputAdornment from '@mui/material/InputAdornment';
import {
  DragHandle,
  RemoveCircleOutline,
  SettingsSuggest,
} from '@mui/icons-material';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import TextField from '../../stories/general-components/TextField';
import IconUploader from './IconUploader';
import Select from '../../stories/general-components/Select';
import GeneralText from '../../stories/general-components/GeneralText';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import {
  fetchBusinessData,
  setGeneralStatus,
} from '../../redux/actions-v2/coreAction';
import Loading from '../../stories/general-components/Loading';

const mapContainerStyle = {
  width: '100%',
  height: '460px',
  borderRadius: '15px',
  overflow: 'hidden',
};

// Generate a custom pin icon with dynamic color
const createCustomPinIcon = (color) => {
  return {
    url:
      `data:image/svg+xml;charset=UTF-8,` +
      encodeURIComponent(`
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="48" height="48">
          <path fill="${color}" d="M12 2C8.1 2 5 5.1 5 9c0 5.6 7 13 7 13s7-7.4 7-13c0-3.9-3.1-7-7-7zm0 9.5c-1.4 0-2.5-1.1-2.5-2.5S10.6 6.5 12 6.5s2.5 1.1 2.5 2.5S13.4 11.5 12 11.5z"/>
        </svg>
      `),
    scaledSize: new window.google.maps.Size(40, 40),
  };
};

const SideExtra = ({
  structure,
  color,
  data,
  secColor,
  fieldSettings,
  setFieldSettings,
  setFields,
  setIsSiteExtraOpen,
  handleSettingsField,
  fields,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentLangI18 = i18n.language || 'en';
  const searchResults = useSelector((state) => state.list.searchResults);
  const theme = useTheme();
  const isDarkmode = theme.palette.mode === 'dark';

  const [selectedMarker, setSelectedMarker] = useState(null);
  const [currentLang, setCurrentLang] = useState('fr');
  const [mapInstance, setMapInstance] = useState(null);
  const [currentRegexIndex, setCurrentRegexIndex] = useState(null);
  const [displayRegexMenu, setDisplayRegexMenu] = useState(false);
  const [currentStructure, setCurrentStructure] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [generalChanges, setGeneralChanges] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const businessPreference = useSelector((state) => state.core.businessData);
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  const fieldTypes = [
    {
      label: t('text'),
      value: 'string',
      id: 'string',
      icon: 'FormatSizeOutlined',
    },
    {
      label: t('privateData'),
      value: 'private',
      id: 'private',
      icon: 'PasswordOutlined',
    },
    {
      label: t('number'),
      value: 'number',
      id: 'number',
      icon: 'NumbersOutlined',
    },
    {
      label: t('boolean'),
      value: 'boolean',
      id: 'boolean',
      icon: 'ToggleOnOutlined',
    },
    {
      label: t('date'),
      value: 'date',
      id: 'date',
      icon: 'CalendarMonthOutlined',
    },
    {
      label: t('dateTime'),
      value: 'date-time',
      id: 'date-time',
      icon: 'WatchLaterOutlined',
    },
    {
      label: t('file'),
      value: 'media',
      id: 'media',
      icon: 'PermMediaOutlined',
    },
    {
      label: t('fileSingle'),
      value: 'media-single',
      id: 'media-single',
      icon: 'InsertPhotoOutlined',
    },
    {
      label: t('fileSinglePrivate'),
      value: 'media-single-private',
      id: 'media-single-private',
      icon: 'GradientOutlined',
    },
    {
      label: t('location'),
      value: 'geo',
      id: 'geo',
      icon: 'FmdGoodOutlined',
    },
    {
      label: t('selection'),
      value: 'selection',
      id: 'selection',
      icon: 'LibraryAddCheckOutlined',
    },
    {
      label: t('dropdown'),
      value: 'dropdown',
      id: 'dropdown',
      icon: 'ArrowDropDownCircleOutlined',
    },
    {
      label: t('slider'),
      value: 'slider',
      id: 'slider',
      icon: 'LinearScaleOutlined',
    },
    {
      label: t('signature'),
      value: 'signature',
      id: 'signature',
      icon: 'DrawOutlined',
    },
    {
      label: t('page'),
      value: 'page',
      id: 'page',
      icon: 'InsertPageBreakOutlined',
    },
    {
      label: t('section'),
      value: 'section',
      id: 'section',
      icon: 'SubtitlesOutlined',
    },
    {
      label: t('tags'),
      value: 'tags',
      id: 'tags',
      icon: 'LocalOfferOutlined',
      restrict: 'single',
    },
    {
      label: t('note'),
      value: 'note',
      id: 'note',
      icon: 'EditNoteOutlined',
      restrict: 'single',
    },
    {
      label: t('status'),
      value: 'status',
      id: 'status',
      icon: 'DynamicFeedOutlined',
      restrict: 'single',
    },
    // {
    //   label: t('search'),
    //   value: 'search',
    //   id: 'search',
    // },
    // {
    //   label: t('hookField'),
    //   value: 'hook',
    //   id: 'hook',
    // },
  ];

  useEffect(() => {
    if (structure?.fields) {
      setFields(structure?.fields);

      const detailedStruc = structure;
      setCurrentStructure({
        ...detailedStruc,
        isPublic: detailedStruc?.element?.isPublic,
        matchedAttributes: detailedStruc?.crossPreferences?.matchedAttributes,
        matchedCollection: detailedStruc?.crossPreferences?.matchedCollection,
        matchedValue: detailedStruc?.crossPreferences?.matchedValue,
        print: detailedStruc?.element?.preferences?.print,
        email: detailedStruc?.element?.preferences?.email,
        edit: detailedStruc?.element?.preferences?.edit,
        delete: detailedStruc?.element?.preferences?.delete,
        creation: detailedStruc?.element?.preferences?.creation,
        favorite: detailedStruc?.element?.preferences?.favorite,
        tags: detailedStruc?.element?.preferences?.tags,
        approvalMethod: detailedStruc?.element?.preferences?.approvalMethod,
        convert: detailedStruc?.element?.preferences?.convert,
        reference: detailedStruc?.element?.preferences?.reference,
        referenceField: detailedStruc?.element?.preferences?.referenceField,
        flowOnApprove: detailedStruc?.element?.preferences?.flowOnApprove,
        approvalTerm: detailedStruc?.element?.preferences?.approvalTerm,
        statusDone: detailedStruc?.element?.preferences?.statusDone,
        statusPending: detailedStruc?.element?.preferences?.statusPending,
        flow: detailedStruc?.element?.preferences?.flow,
        share: detailedStruc?.element?.preferences?.share,
        billField: detailedStruc?.element?.preferences?.billField,
        shipField: detailedStruc?.element?.preferences?.shipField,
        trackActivity: detailedStruc?.element?.publicPage?.trackActivity,
        accessCodeRequired:
          detailedStruc?.element?.publicPage?.accessCodeRequired,
        view: detailedStruc?.element?.publicPage?.view || 'default',
        viewForm: detailedStruc?.element?.publicForm?.view || 'default',
        layout: detailedStruc?.element?.publicForm?.layout,
        displayLogo: detailedStruc?.element?.publicForm?.displayLogo ?? true,
        bgImage: detailedStruc?.element?.publicForm?.bgImage,
        btnStart:
          detailedStruc?.element?.publicForm?.['btnStart_' + currentLang],
        intro: detailedStruc?.element?.publicForm?.['intro_' + currentLang],
        bottomText:
          detailedStruc?.element?.publicPage?.['bottomText_' + currentLang],
        nps: detailedStruc?.element?.preferences?.nps,
        npsField: detailedStruc?.element?.preferences?.npsField,
        npsDays: detailedStruc?.element?.preferences?.npsDays,
        npsEmail:
          detailedStruc?.element?.preferences?.['npsEmail_' + currentLang],
        npsTemplate:
          detailedStruc?.element?.preferences?.['npsTemplate_' + currentLang],
        npsCommentMsg:
          detailedStruc?.element?.preferences?.['npsCommentMsg_' + currentLang],
      });
    }
  }, [structure?.id]);

  useEffect(() => {
    if (fieldSettings) {
      setCurrentTab(0);
      setConfirmDelete(false);
      setDisplayRegexMenu(false);
      setCurrentRegexIndex(null);
    }
  }, [fieldSettings]);

  useEffect(() => {
    if (currentLangI18 === 'fr') {
      if (currentTab === 0) {
        setCurrentLang('fr');
      } else {
        setCurrentLang('en');
      }
    } else if (currentLangI18 === 'en') {
      if (currentTab === 0) {
        setCurrentLang('en');
      } else {
        setCurrentLang('fr');
      }
    }
  }, [currentLangI18, currentTab]);

  const field = fields?.find((field) => field?.value === fieldSettings);
  const fieldIndex = fields?.findIndex(
    (field) => field?.value === fieldSettings
  );

  const index = fields?.findIndex((field) => field?.value === fieldSettings);

  const handleFieldChange = (index, key, value) => {
    setFields(
      fields?.map((field, idx) =>
        idx === index ? { ...field, [key]: value } : field
      )
    );
  };

  const handleSelectionChange = (selectionIndex, key, value) => {
    const updatedFields = fields?.map((field, idx) => {
      if (idx === fieldIndex) {
        if (field?.typeData === 'selection' || field?.typeData === 'dropdown') {
          if (key === 'label_fr' || key === 'label_en') {
            const updatedSelections = field?.selections?.map((sel, selIdx) => {
              return selIdx === selectionIndex ? { ...sel, [key]: value } : sel;
            });
            return { ...field, selections: updatedSelections };
          } else {
            const updatedSelections = field?.selections?.map((sel, selIdx) => {
              return selIdx === selectionIndex ? { ...sel, [key]: value } : sel;
            });
            return { ...field, selections: updatedSelections };
          }
        } else {
          const updatedSelections = field?.selections?.map((sel, selIdx) => {
            return selIdx === selectionIndex ? { ...sel, [key]: value } : sel;
          });
          return { ...field, selections: updatedSelections };
        }
      }
      return field;
    });
    setFields(updatedFields);
  };

  const handleDisplayRegex = (index) => {
    setDisplayRegexMenu(!displayRegexMenu);
    setCurrentRegexIndex(index);
  };

  const handleSelectRegex = (index, value) => {
    handleFieldChange(index, 'validation', value);
    setDisplayRegexMenu(false);
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleMapsApiKey,
  });

  const defaultCenter = {
    lat: searchResults?.[0]?._geoloc?.lat || 45.5017,
    lng: searchResults?.[0]?._geoloc?.lng || -73.5673,
  };

  const onLoad = useCallback(
    (map) => {
      setMapInstance(map);
      if (searchResults?.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        searchResults.forEach((result) => {
          if (result?._geoloc?.lat && result?._geoloc?.lng) {
            bounds.extend(
              new window.google.maps.LatLng(
                result._geoloc.lat,
                result._geoloc.lng
              )
            );
          }
        });
        map.fitBounds(bounds);
      }
    },
    [searchResults]
  );

  useEffect(() => {
    if (mapInstance && searchResults?.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      searchResults.forEach((result) => {
        if (result?._geoloc?.lat && result?._geoloc?.lng) {
          bounds.extend(
            new window.google.maps.LatLng(
              result._geoloc.lat,
              result._geoloc.lng
            )
          );
        }
      });
      mapInstance.fitBounds(bounds);
    }
  }, [mapInstance, searchResults]);

  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  };

  const saveStructureFields = async (fieldsData) => {
    try {
      setIsLoading(true);

      const fieldsToSave = fieldsData === 'origin' ? fields : fieldsData;

      if (fieldsToSave !== structure?.fields) {
        await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `business/structure`,
          showLoading: true,
          body: {
            type: 'fields',
            lang: currentLang,
            structureId: structure?.id,
            data: {
              fieldsData: fieldsToSave,
            },
          },
        });
      }

      setIsLoading(false);
      dispatch(fetchBusinessData(businessPreference?.id, t));
      setFieldSettings(null);
      setCurrentLang(i18n.language === 'fr' ? 'fr' : 'en');
      setCurrentTab(0);
      setIsSiteExtraOpen(false);
    } catch (error) {
      console.error('Error updating structure');
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };

  const saveStructure = async () => {
    try {
      setIsLoading(true);
      if (generalChanges) {
        await nodeAxiosFirebase({
          t,
          method: 'POST',
          url: `business/structure`,
          body: {
            type: 'general',
            lang: currentLang,
            structureId: currentStructure?.id,
            data: {
              name: currentStructure?.name,
              icon: currentStructure?.icon,
              attribute1: currentStructure?.attribute1,
              attribute2: currentStructure?.attribute2,
              attribute3: currentStructure?.attribute3,
              attribute4: currentStructure?.attribute4,
              isPublic: currentStructure?.isPublic,
              print: currentStructure?.print,
              email: currentStructure?.email,
              edit: currentStructure?.edit,
              delete: currentStructure?.delete,
              creation: currentStructure?.creation,
              favorite: currentStructure?.favorite,
              tags: currentStructure?.tags,
              convert: currentStructure?.convert,
              reference: currentStructure?.reference,
              referenceField: currentStructure?.referenceField,
              flowOnApprove: currentStructure?.flowOnApprove,
              approvalTerm: currentStructure?.approvalTerm,
              statusDone: currentStructure?.statusDone,
              statusPending: currentStructure?.statusPending,
              flow: currentStructure?.flow,
              share: currentStructure?.share,
              trackActivity: currentStructure?.trackActivity,
              accessCodeRequired: currentStructure?.accessCodeRequired,
              view: currentStructure?.view,
              displayLogo: currentStructure?.displayLogo ?? true,
              viewForm: currentStructure?.viewForm || 'default',
              layout: currentStructure?.layout || 'fw',
              bgImage: currentStructure?.bgImage,
              approvalMethod: currentStructure?.approvalMethod,
              btnStart: currentStructure?.btnStart,
              intro: currentStructure?.intro,
              bottomText: currentStructure?.bottomText,
              nps: currentStructure?.nps,
              npsField: currentStructure?.npsField,
              npsDays: currentStructure?.npsDays,
              npsEmail: currentStructure?.npsEmail,
              npsCommentMsg: currentStructure?.npsCommentMsg,
              npsTemplate: currentStructure?.npsTemplate,
              matchedAttributes: currentStructure?.matchedAttributes,
              matchedCollection: currentStructure?.matchedCollection,
              matchedValue: currentStructure?.matchedValue,
            },
          },
        });
      }
      setIsLoading(false);
      dispatch(fetchBusinessData(businessPreference?.id, t));
      setFieldSettings(null);
      setCurrentLang(i18n.language === 'fr' ? 'fr' : 'en');
      setCurrentTab(0);
      setIsSiteExtraOpen(false);
    } catch (error) {
      console.error('Error updating structure');
      dispatch(setGeneralStatus({ status: 'error', error: error }));
    }
  };

  const handleSaveField = (field, value) => {
    setGeneralChanges(true);
    setCurrentStructure((currentSelectedStructure) => ({
      ...currentSelectedStructure,
      [field]: value,
    }));
  };

  const publicField = (index) => {
    setFields(
      fields?.map((field, idx) =>
        idx === index ? { ...field, public: !field?.public } : field
      )
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(fields);
    const [reorderedItem] = items?.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFields(items);
  };

  const removeFieldConfirm = () => {
    setConfirmDelete(true);
  };

  const addField = async (field) => {
    try {
      setIsLoading(true);

      if (field?.restrict === 'single') {
        const existingField = fields?.find((f) => f?.value === field?.value);
        if (existingField) {
          return;
        }
      }

      // Create the new field
      const newField = {
        id: uuidv4(),
        name: t('new'),
        name_fr: 'Nouveau',
        name_en: 'New',
        label: '',
        value: '',
        validation: '',
        typeData: field?.value || 'string',
        defaultValue: '',
        help: '',
        type: field?.restrict === 'single' ? 'default' : 'data',
        selections: [],
        conditional: [],
        public: false,
      };

      // Update fields locally and set state
      const updatedFields = [...fields, newField];

      setFields(updatedFields);

      // Pass updatedFields to saveStructureFields to ensure consistency
      await saveStructureFields(updatedFields);

      // Set field settings for the new field
      const lastAddedFieldValue = newField.value;
      setFieldSettings(lastAddedFieldValue);

      // Fetch updated business data
      await dispatch(fetchBusinessData(businessPreference?.id, t));
    } catch (error) {
      console.error('Error in addField:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const removeField = (index) => {
    const updatedFields = fields.filter((_, idx) => idx !== index);
    setFields(updatedFields);
    setFieldSettings(null);
    saveStructureFields(updatedFields);
  };

  const setRequired = (index, value) => {
    setFields(
      fields?.map((field, idx) =>
        idx === index ? { ...field, required: value } : field
      )
    );
  };

  const addMedia = (mediaUrl, fieldIndex, selectionIndex) => {
    const updatedFields = fields?.map((field, idx) => {
      if (idx === fieldIndex) {
        const updatedSelections = field?.selections?.map((sel, selIdx) => {
          return selIdx === selectionIndex
            ? { ...sel, media_url: mediaUrl?.[0]?.fileUrl }
            : sel;
        });
        return { ...field, selections: updatedSelections };
      }
      return field;
    });
    setFields(updatedFields);
  };

  const addSelectionToField = (fieldIndex) => {
    const updatedFields = fields?.map((field, idx) => {
      if (idx === fieldIndex) {
        const newSelection = {
          id: uuidv4(),
          label_fr: '',
          label_en: '',
          value: field?.selections?.length,
          color: '#696969',
          icon: 'Info',
          media_url: '',
        };
        const updatedSelections = [...(field.selections || []), newSelection];
        return { ...field, selections: updatedSelections };
      }
      return field;
    });

    setFields(updatedFields);
  };

  const removeSelectionField = (fieldIndex) => {
    const updatedFields = fields?.map((field, idx) => {
      if (idx === fieldIndex) {
        const updatedSelections = field.selections.slice(0, -1);
        return { ...field, selections: updatedSelections };
      }
      return field;
    });
    setFields(updatedFields);
  };

  const renderSelectionManagement = (field, fieldIndex) => {
    const selections = Array.isArray(field?.selections)
      ? field?.selections
      : [];

    return (
      <>
        {selections?.map((selection, selectionIndex) => {
          if (typeof selection === 'string') {
            return null;
          } else {
            return (
              <FormControl
                fullWidth
                key={selectionIndex + 'select'}
                margin="normal"
                sx={{
                  border: '1px solid lightgray',
                  borderRadius: '10px',
                  padding: '10px',
                  minHeight: '50px',
                  '& .MuiOutlinedInput-root': {
                    backgroundColor: isDarkmode ? 'rgb(51,51,51)' : '#FFFFFF',
                  },
                }}
              >
                {' '}
                <InputLabel
                  shrink={true}
                  sx={{
                    backgroundColor: isDarkmode ? 'rgb(51,51,51)' : '#FFF',
                    padding: '2px 10px 2px 10px',
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: isDarkmode ? 'rgb(51,51,51)' : '#FFFFFF',
                    },
                  }}
                >
                  {t('selection') + ' ' + (selectionIndex + 1)}
                </InputLabel>
                <div>
                  <div className={'col-12'}>
                    <TextFieldMUI
                      variant="standard"
                      sx={{
                        mt: 0.8,
                      }}
                      value={selection?.['label_' + currentLang]}
                      onChange={(e) =>
                        handleSelectionChange(
                          selectionIndex,
                          'label_' + currentLang,
                          e.target.value
                        )
                      }
                      fullWidth
                    />
                  </div>
                  <div className="d-flex middle-content">
                    <div className={'col-3'}>
                      <TextFieldMUI
                        type="color"
                        variant="standard"
                        sx={{
                          mt: 0.8,
                        }}
                        value={selection?.color}
                        onChange={(e) =>
                          handleSelectionChange(
                            selectionIndex,
                            'color',
                            e.target.value
                          )
                        }
                        fullWidth
                      />
                    </div>
                    {(field?.typeData === 'select' ||
                      field?.typeData === 'selection' ||
                      field?.typeData === 'status') && (
                      <div className={'col-5'}>
                        <TextFieldMUI
                          select
                          sx={{
                            px: 3,
                          }}
                          variant="standard"
                          value={selection?.icon}
                          onChange={(e) =>
                            handleSelectionChange(
                              selectionIndex,
                              'icon',
                              e.target.value
                            )
                          }
                          fullWidth
                        >
                          <MenuItem value="AssignmentInd">
                            <AssignmentIndIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('assignment')}
                          </MenuItem>
                          <MenuItem value="Task">
                            <TaskIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('task')}
                          </MenuItem>
                          <MenuItem value="Category">
                            <CategoryIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('objects')}
                          </MenuItem>
                          <MenuItem value="Cancel">
                            <CancelIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('cancelled')}
                          </MenuItem>
                          <MenuItem value="Inventory">
                            <InventoryIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('box')}
                          </MenuItem>
                          <MenuItem value="ContactSupport">
                            <ContactSupportIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('support')}
                          </MenuItem>
                          <MenuItem value="EventNote">
                            <EventNoteIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('calendar')}
                          </MenuItem>
                          <MenuItem value="ThumbUp">
                            <ThumbUpIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('approved')}
                          </MenuItem>
                          <MenuItem value="ThumbDown">
                            <ThumbDownIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('rejected')}
                          </MenuItem>
                          <MenuItem value="HourglassFull">
                            <HourglassFullIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('pending')}
                          </MenuItem>
                          <MenuItem value="Lock">
                            <LockIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('lock')}
                          </MenuItem>
                          <MenuItem value="Warning">
                            <WarningIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('priority')}
                          </MenuItem>
                          <MenuItem value="Info">
                            <InfoIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('Info')}
                          </MenuItem>
                          <MenuItem value="Visibility">
                            <VisibilityIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('Visible')}
                          </MenuItem>
                          <MenuItem value="Person">
                            <PersonIcon
                              htmlColor={selection?.color}
                              sx={{ mr: 1 }}
                            />
                            {t('user')}
                          </MenuItem>
                        </TextFieldMUI>
                      </div>
                    )}
                    {field?.typeData === 'status' && (
                      <div className={'col-2'}>
                        <Tooltip title={t('considerIsDone')}>
                          <span>
                            <CheckboxMUI
                              key={selectionIndex + 'isDone'}
                              checked={selection?.isDone}
                              onClick={(e) =>
                                handleSelectionChange(
                                  selectionIndex,
                                  'isDone',
                                  !selection?.isDone
                                )
                              }
                            />
                          </span>
                        </Tooltip>
                      </div>
                    )}
                    {field?.typeData === 'dropdown' && (
                      <div className={'col-1 align-c'}>
                        <IconUploader
                          size="small"
                          value={selection?.media_url}
                          onComplete={(e) =>
                            addMedia(e, fieldIndex, selectionIndex)
                          }
                        />
                      </div>
                    )}
                    <div className={'col-1 align-c'}>
                      {' '}
                      <IconButton
                        onClick={() => removeSelectionField(fieldIndex)}
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </FormControl>
            );
          }
        })}
        <div className="mt-1">
          <Button
            variant="text"
            size="small"
            label={t('addSelection')}
            color="black"
            onClick={() => addSelectionToField(fieldIndex)}
          />
        </div>
      </>
    );
  };

  const disabledResolve = (type) => {
    if (fields?.find((f) => f?.value === type?.value)) {
      if (type?.restrict === 'single') {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  if (!isLoaded)
    return (
      <div className="align-c">
        <Loading type="logo" size="small" />
      </div>
    );

  return (
    <div style={{ paddingLeft: '25px', marginTop: '16px' }}>
      {fieldSettings &&
        fieldSettings !== 'SETTINGS' &&
        fieldSettings !== 'ATTRIBUTES' &&
        fieldSettings !== 'ADDFIELD' &&
        fieldSettings !== 'REORDER' &&
        fieldSettings !== 'STRUCTURE' && (
          <div>
            <Box key={index + 'fieldSec'}>
              <div className="align-c">
                <div style={{ marginLeft: '65px' }}>
                  <Tabs
                    value={currentTab}
                    onChange={(e, value) => setCurrentTab(value)}
                  >
                    <Tab label={currentLang === 'fr' ? t('fr') : t('en')} />
                    <Tab label={currentLang === 'fr' ? t('en') : t('fr')} />
                  </Tabs>
                </div>
                <div style={{ paddingTop: '14px' }}>
                  <TextField
                    label={t('name')}
                    value={field?.['name_' + currentLang]}
                    help={'ID: ' + field?.value}
                    size="small"
                    key={index + 'name' + currentLang + currentTab}
                    onChange={(e) =>
                      handleFieldChange(
                        index,
                        'name_' + currentLang,
                        e.target.value
                      )
                    }
                    fullWidth
                  />
                </div>
                <div>
                  <Select
                    disabled={field?.type === 'default'}
                    label={t('type')}
                    size="small"
                    noEmpty
                    key={index + 'type'}
                    value={field?.typeData}
                    selections={fieldTypes}
                    onChange={(e, value) =>
                      handleFieldChange(index, 'typeData', value)
                    }
                    fullWidth
                  />
                </div>
                <div className="col-12">
                  {(field?.typeData === 'selection' ||
                    field?.typeData === 'dropdown' ||
                    field?.typeData === 'slider' ||
                    field?.typeData === 'status') &&
                    renderSelectionManagement(field, index)}
                </div>
                <div>
                  {field?.typeData !== 'page' &&
                    field?.typeData !== 'section' && (
                      <Select
                        disabled={field?.typeData !== 'string'}
                        label={t('transform')}
                        size="small"
                        noEmpty
                        key={index + 'transform'}
                        value={field?.transform || 'none'}
                        selections={[
                          {
                            label: t('uppercase'),
                            value: 'uppercase',
                            id: 'uppercase',
                          },
                          {
                            label: t('lowercase'),
                            value: 'lowercase',
                            id: 'lowercase',
                          },
                          {
                            label: t('capitalize'),
                            value: 'capitalize',
                            id: 'string',
                          },
                          {
                            label: t('trim'),
                            value: 'trim',
                            id: 'trim',
                          },
                          {
                            label: t('none'),
                            value: 'none',
                            id: 'none',
                          },
                        ]}
                        onChange={(e, value) =>
                          handleFieldChange(index, 'transform', value)
                        }
                        fullWidth
                      />
                    )}
                </div>
                <div>
                  <TextField
                    label={t('helpText')}
                    value={field?.['help_' + currentLang]}
                    size="small"
                    key={index + 'help' + currentLang + currentTab}
                    onChange={(e) =>
                      handleFieldChange(
                        index,
                        'help_' + currentLang,
                        e.target.value
                      )
                    }
                    fullWidth
                  />
                </div>
                <div className="mt-3">
                  {field?.typeData !== 'page' &&
                    field?.typeData !== 'section' && (
                      <div>
                        <TextFieldMUI
                          label={t('regex')}
                          value={field?.validation || ''}
                          size="small"
                          key={index + 'regex' + field?.name}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              'validation',
                              e.target.value
                            )
                          }
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    handleDisplayRegex(index);
                                  }}
                                >
                                  <SettingsSuggest />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            'MuiFormControl-root': {
                              width: '100%',
                            },
                            '.MuiInputBase-input': {
                              height: '34px',
                              padding: '2px 10px 2px 10px',
                            },
                            '& .MuiFormLabel-root': {
                              padding: '2px 10px 2px 10px',
                              borderRadius: '10px',
                            },
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '10px',
                            },
                          }}
                        />
                        <Menu
                          anchorEl={displayRegexMenu}
                          open={Boolean(displayRegexMenu)}
                          onClose={() => setDisplayRegexMenu(false)}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                          sx={{
                            '.MuiMenu-paper': {
                              width: '300px',
                            },
                          }}
                          PaperProps={{
                            sx: {
                              width: '300px',
                              elevation: 1,
                              boxShadow: '0px 0px 0px 0px',
                            },
                          }}
                        >
                          <GeneralText
                            text={t('regex')}
                            fontSize="14px"
                            size="bold"
                            primary={true}
                            classNameComponent="p-4"
                            beta
                          />
                          <div className="row px-3">
                            <MenuItem
                              key={currentRegexIndex + 'regex-email'}
                              onClick={() => {
                                handleSelectRegex(
                                  currentRegexIndex,
                                  '/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/'
                                );
                              }}
                            >
                              {t('email')}
                            </MenuItem>
                            <MenuItem
                              key={currentRegexIndex + 'regex-phone'}
                              onClick={() => {
                                handleSelectRegex(
                                  currentRegexIndex,
                                  '/^+?d{1,4}?[-. ]?(?(?:d{1,3}?)?[-. ]?)?(?:d{1,4}[-. ]?){2}d{1,9}$/'
                                );
                              }}
                            >
                              {t('phone')}
                            </MenuItem>
                            <MenuItem
                              key={currentRegexIndex + 'regex-url'}
                              onClick={() => {
                                handleSelectRegex(
                                  currentRegexIndex,
                                  '/^(http|https)://[^ "]+$'
                                );
                              }}
                            >
                              {t('url')}
                            </MenuItem>
                            <MenuItem
                              key={currentRegexIndex + 'regex-number'}
                              onClick={() => {
                                handleSelectRegex(
                                  currentRegexIndex,
                                  '/^[0-9]*$/'
                                );
                              }}
                            >
                              {t('numberOnly')}
                            </MenuItem>
                            <MenuItem
                              key={currentRegexIndex + 'regex-alphanumeric'}
                              onClick={() => {
                                handleSelectRegex(
                                  currentRegexIndex,
                                  '/^[a-zA-Z0-9]*$/'
                                );
                              }}
                            >
                              {t('alphanumeric')}
                            </MenuItem>
                            <MenuItem
                              key={currentRegexIndex + 'regex-tiresize'}
                              onClick={() => {
                                handleSelectRegex(
                                  currentRegexIndex,
                                  '^d{3}/d{2}Rd{2}$'
                                );
                              }}
                            >
                              {t('tireSize')}
                            </MenuItem>
                            <MenuItem
                              key={currentRegexIndex + 'regex-VIN'}
                              onClick={() => {
                                handleSelectRegex(
                                  currentRegexIndex,
                                  '/^[A-HJ-NPR-Z0-9]{17}$/'
                                );
                              }}
                            >
                              {t('VIN')}
                            </MenuItem>
                            <MenuItem
                              key={currentRegexIndex + 'regex-address'}
                              onClick={() => {
                                handleSelectRegex(
                                  currentRegexIndex,
                                  '/^[a-zA-Z0-9 .,]*$/'
                                );
                              }}
                            >
                              {t('address')}
                            </MenuItem>
                            <MenuItem
                              key={
                                currentRegexIndex + 'regex-oneLetter-oneNumber'
                              }
                              onClick={() => {
                                handleSelectRegex(
                                  currentRegexIndex,
                                  '/^(?=.*[A-Za-z])(?=.*d)[A-Za-zd]{8,}$/'
                                );
                              }}
                            >
                              {t('oneLetter-oneNumber')}
                            </MenuItem>
                          </div>
                        </Menu>
                      </div>
                    )}
                </div>

                {field?.typeData !== 'page' &&
                  field?.typeData !== 'section' && (
                    <div className="col-12 mt-3">
                      <Checkbox
                        label={t('isRequired')}
                        value={field?.required}
                        onChange={() => setRequired(index, !field?.required)}
                      />
                    </div>
                  )}
                <div className="col-12 mt-1">
                  <Checkbox
                    label={t('publicVisibility')}
                    value={field?.public}
                    onChange={() => publicField(index)}
                  />
                </div>

                <div className="row align-c mt-3 px-3">
                  <div className={confirmDelete ? 'hide' : 'col-6'}>
                    <Button
                      fullWidth
                      size="small"
                      label={t('save')}
                      variant="contained"
                      onClick={() => saveStructureFields('origin')}
                    />
                  </div>
                  {field?.type !== 'default' && (
                    <div className={confirmDelete ? 'col-12' : 'col-6'}>
                      <Button
                        fullWidth
                        size="small"
                        label={confirmDelete ? t('confirmDelete') : t('delete')}
                        buttonSx={{
                          color: 'red',
                        }}
                        variant="text"
                        onClick={
                          confirmDelete
                            ? () => removeField(index)
                            : () => removeFieldConfirm()
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </Box>
          </div>
        )}

      {fieldSettings && fieldSettings === 'SETTINGS' && (
        <div className="px-3">
          <Box key={index + 'fieldSecSetting'}>
            <div>
              <GeneralText
                text={t('settings')}
                fontSize="16px"
                size="bold"
                primary={true}
                beta
              />
            </div>
            <div className="mt-3">
              <Button
                fullWidth
                size="small"
                label={t('addField')}
                variant="contained"
                onClick={() => handleSettingsField('ADDFIELD')}
              />
            </div>
            <div className="mt-3">
              <Button
                fullWidth
                size="small"
                label={t('reorder')}
                variant="contained"
                onClick={() => handleSettingsField('REORDER')}
              />
            </div>
            <div className="mt-3">
              <Button
                fullWidth
                size="small"
                label={t('attributes')}
                variant="contained"
                onClick={() => handleSettingsField('ATTRIBUTES')}
              />
            </div>

            <div className="mt-4">
              <Button
                fullWidth
                size="small"
                label={t('cancel')}
                variant="text"
                onClick={() => setIsSiteExtraOpen(false)}
              />
            </div>
          </Box>
        </div>
      )}

      {fieldSettings && fieldSettings === 'ATTRIBUTES' && (
        <div>
          <div className="px-3">
            <GeneralText
              text={t('attributes')}
              fontSize="16px"
              size="bold"
              primary={true}
            />
          </div>
          <Box key={index + 'fieldSecSetting'}>
            <div className="mb-3 mt-1 px-3">
              <div>
                <Select
                  label={t('attribute1')}
                  staticView
                  noEmpty
                  key={structure?.id + 'attribute1'}
                  selections={fields?.map((field) => {
                    return {
                      value: field?.value,
                      id: field?.value,
                      label: field?.name,
                    };
                  })}
                  value={structure?.attribute1}
                  onChange={(e, value) => handleSaveField('attribute1', value)}
                  fullWidth
                />
              </div>
              <div>
                <Select
                  select
                  staticView
                  noEmpty
                  key={structure?.id + 'attribute2'}
                  label={t('attribute2')}
                  value={structure?.attribute2}
                  selections={structure?.fields?.map((field) => {
                    return {
                      value: field?.value,
                      id: field?.value,
                      label: field?.name,
                    };
                  })}
                  onChange={(e, value) => handleSaveField('attribute2', value)}
                  fullWidth
                />
              </div>
              <div>
                <Select
                  select
                  staticView
                  noEmpty
                  key={structure?.id + 'attribute3'}
                  label={t('attribute3')}
                  value={structure?.attribute3}
                  selections={structure?.fields?.map((field) => {
                    return {
                      value: field?.value,
                      id: field?.value,
                      label: field?.name,
                    };
                  })}
                  onChange={(e, value) => handleSaveField('attribute3', value)}
                  fullWidth
                />
              </div>
              <div>
                <Select
                  select
                  staticView
                  noEmpty
                  key={structure?.id + 'attribute4'}
                  label={t('attribute4')}
                  value={structure?.attribute4}
                  selections={structure?.fields?.map((field) => {
                    return {
                      value: field?.value,
                      id: field?.value,
                      label: field?.name,
                    };
                  })}
                  onChange={(e, value) => handleSaveField('attribute4', value)}
                  fullWidth
                />
              </div>
            </div>
            <Button
              fullWidth
              size="small"
              label={t('save')}
              variant="contained"
              onClick={() => saveStructure()}
            />
          </Box>
        </div>
      )}
      {fieldSettings && fieldSettings === 'ADDFIELD' && (
        <div>
          <div className="px-3">
            <GeneralText
              text={t('addField')}
              fontSize="16px"
              size="bold"
              primary={true}
            />
          </div>
          <Box key={index + 'fieldSecSetting'}>
            <div style={{ height: '55vh', overflow: 'scroll' }}>
              {fieldTypes?.map((type) => {
                return (
                  <div className="mt-3 mb-3 px-3">
                    <Button
                      fullWidth
                      size="small"
                      label={type?.label}
                      variant="text"
                      disabled={disabledResolve(type)}
                      startIcon={type?.icon}
                      onClick={() => addField(type)}
                    />
                  </div>
                );
              })}
            </div>
          </Box>
        </div>
      )}

      {fieldSettings && fieldSettings === 'REORDER' && (
        <div className="px-3">
          <div>
            <GeneralText
              text={t('reorder')}
              fontSize="16px"
              size="bold"
              primary={true}
            />
          </div>
          <Box key={index + 'fieldSecSetting'}>
            <div className="mt-3 mb-4">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="fields">
                  {(provided) => (
                    <Box {...provided.droppableProps} ref={provided.innerRef}>
                      {fields?.map((field, index) => (
                        <Draggable
                          key={index + 'field-drop'}
                          draggableId={
                            field?.id ? field.id + index : `field-${index}`
                          }
                          index={index}
                        >
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              key={index + 'field'}
                            >
                              <Box key={index + 'fieldSec'}>
                                <ListItem divider dense sx={{ m: 0, p: 0 }}>
                                  <div className="row py-2 justify-content-between">
                                    <div className="col-10">
                                      <GeneralText
                                        text={field?.name}
                                        fontSize="14px"
                                        size="medium"
                                        primary={true}
                                      />
                                    </div>
                                    <div className="col-2">
                                      <DragHandle htmlColor="#69696990" />
                                    </div>
                                  </div>
                                </ListItem>
                              </Box>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <Button
              fullWidth
              size="small"
              label={t('save')}
              variant="contained"
              onClick={() => saveStructureFields('origin')}
            />
          </Box>
        </div>
      )}

      {fieldSettings && fieldSettings === 'STRUCTURE' && (
        <div className="px-3">
          <div>
            <GeneralText
              text={t('structure')}
              fontSize="16px"
              size="bold"
              primary={true}
            />
          </div>
          <Box key={index + 'fieldSecSetting'}></Box>
        </div>
      )}

      {(structure?.sideExtra === 'map' ||
        structure?.element?.preferences?.sideExtra === 'map') &&
        isLoaded &&
        searchResults?.length > 0 && (
          <div>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={defaultCenter}
              zoom={10}
              onLoad={onLoad}
              options={mapOptions}
            >
              {searchResults?.map((result, index) => {
                const { _geoloc, targetId } = result;
                if (!_geoloc?.lat || !_geoloc?.lng) return null;

                const isInTargetList = data?.targetId?.includes(
                  'users/' + targetId
                );
                const pinColor = isInTargetList ? secColor : color;

                return (
                  <Marker
                    key={index}
                    position={{
                      lat: _geoloc.lat,
                      lng: _geoloc.lng,
                    }}
                    icon={createCustomPinIcon(pinColor)} // Apply custom-colored pin
                    title={result?.name || `Location ${index + 1}`}
                    onClick={() => setSelectedMarker(result)}
                  />
                );
              })}
            </GoogleMap>
            {selectedMarker && (
              <div
                style={{
                  marginTop: '15px',
                  padding: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  background: '#fff',
                }}
              >
                <h3>{selectedMarker?.name || 'Location'}</h3>
                <p>
                  <strong>{t('address')}:</strong>{' '}
                  {selectedMarker?.targetAddress || '-'}
                </p>
                <p>
                  <strong>{t('phone')}:</strong>{' '}
                  {selectedMarker?.targetPhone || '-'}
                </p>
              </div>
            )}
          </div>
        )}
    </div>
  );
};

export default SideExtra;
