import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import errorData from './lotties/error.json';

//general
import PrivateRoute from './components/PrivateRoute';
import Settings from './screens/settings/Settings';
import UsageBilling from './screens/settings/UsageBilling';
import SettingsColletions from './screens/settings/SettingsCollections';
import SettingsTeam from './screens/settings/SettingsTeam';
import ElementDetails from './screens/mainPages/ElementDetails';
import ModuleList from './screens/mainPages/ModuleList';
import NodeMaster from './screens/specific/NodeMaster';
import Node from './screens/specific/Node';
import Dashboard from './screens/mainPages/Dashboard';
import EmailsCreator from './screens/website/EmailsCreator';
import SettingsLocations from './screens/settings/SettingsLocations';
import ListDrop from './screens/lists/ListDrop';
import Inbox from './screens/mainPages/Inbox';
import FrameWeb from './screens/mainPages/FrameWeb';
import Scheduled from './screens/specific/Scheduled';
import OverviewFlows from './screens/specific/OverviewFlows';
import JcraqueInterieurementPourToiMonCoco from './sandbox/JcraqueInterieurementPourToiMonCoco';
import UserParams from './screens/mainPages/UserParams';
import NodeAI from './sidebars/NodeAI';
import DashboardNode from './screens/specific/DashboardNode';

const PrivateRoutes = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/sandbox"
        element={
          <PrivateRoute
            permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
          >
            <JcraqueInterieurementPourToiMonCoco />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard-node"
        element={
          <PrivateRoute
            permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
          >
            <DashboardNode />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute
            permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
          >
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/me"
        element={
          <PrivateRoute
            permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
          >
            <UserParams />
          </PrivateRoute>
        }
      />
      <Route
        path="/node-ai"
        element={
          <PrivateRoute
            permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
          >
            <NodeAI />
          </PrivateRoute>
        }
      />
      [///////////////////////// General //////////////////////////]
      <Route
        path="/:collection/:moduleName/:moduleId/:structureId"
        element={
          <PrivateRoute permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER']}>
            <ModuleList />
          </PrivateRoute>
        }
      />
      <Route
        path="/:collection/:moduleName/:moduleId/:structureId/:segmentId"
        element={
          <PrivateRoute permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER']}>
            <ModuleList />
          </PrivateRoute>
        }
      />
      <Route
        path="/element/:moduleName/:structureId/:elementId"
        element={
          <PrivateRoute permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER']}>
            <ElementDetails />
          </PrivateRoute>
        }
      />
      <Route
        path="/nodes/:nodeId"
        element={
          <PrivateRoute permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER']}>
            <ListDrop />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/flows"
        element={
          <PrivateRoute permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER']}>
            <OverviewFlows />
          </PrivateRoute>
        }
      />
      [///////////////////////// Finances //////////////////////////]
      <Route
        path="/settings/master"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <NodeMaster />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/scheduled"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <Scheduled />
          </PrivateRoute>
        }
      />
      <Route
        path="/inbox"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <Inbox />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/locations"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <SettingsLocations />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/page"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <Node />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/preferences"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <Node />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/usage"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <UsageBilling />
          </PrivateRoute>
        }
      />
      <Route
        path="/links/:linkIndex"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <FrameWeb />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <Settings />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/structures"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <SettingsColletions />
          </PrivateRoute>
        }
      />
      <Route
        path="/settings/team"
        element={
          <PrivateRoute permission={['ADMIN', 'OWNER']}>
            <SettingsTeam />
          </PrivateRoute>
        }
      />
      [///////////////////////// Extensions //////////////////////////]
      <Route path="/tools/redactor" element={<EmailsCreator />} />
    </Routes>
  );
};

export default PrivateRoutes;
