import React from 'react';

const NodeInsights = ({
  primaryColor = '#0f0a0a',
  secondaryColor = '#b21f1f',
}) => {
  return (
    <svg
      id="Calque_2-In"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 95.32 88.84"
      width="100%"
      height="100%"
    >
      <defs>
        <style>
          {`
            .cls-1-In {
              fill: ${primaryColor};
            }
            .cls-2-In {
              fill: ${secondaryColor};
              opacity: 0.65;
            }
          `}
        </style>
      </defs>
      <g id="Calque_1-2-In">
        <rect
          className="cls-1-In"
          x="40.95"
          width="54.37"
          height="88.84"
          rx="7.24"
          ry="7.24"
        />
        <rect
          className="cls-2-In"
          y="40.06"
          width="48.78"
          height="48.78"
          rx="6.3"
          ry="6.3"
        />
      </g>
    </svg>
  );
};

export default NodeInsights;
