import React from 'react';

const TrialIcon = ({ fill1 = '#200ef0', fill2 = '#200ea7', ...props }) => (
  <svg
    id="Calque_2"
    data-name="Calque 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 199.93 199.93"
    {...props}
  >
    <defs>
      <style>
        {`
        .cls-1 { fill: ${fill1}; }
        .cls-2 { fill: ${fill2}; }
      `}
      </style>
    </defs>
    <g id="Calque_1-2" data-name="Calque 1">
      <g>
        <path
          className="cls-1"
          d="M98.58,199.92c54.55.76,99.25-42.17,101.35-96.03.08-2.16-1.66-3.96-3.82-3.96h-21.37c-2.05,0-3.72,1.61-3.82,3.65-1.93,38.08-33.86,68.24-72.62,67.33-37.66-.89-68.38-31.6-69.27-69.27-.91-38.77,29.26-70.7,67.35-72.62,2.03-.1,3.63-1.77,3.63-3.8V3.8c0-2.15-1.79-3.88-3.94-3.8C42.19,2.09-.75,46.79,0,101.35c.75,53.86,44.71,97.82,98.57,98.57Z"
        />
        <ellipse
          className="cls-2"
          cx="99.96"
          cy="99.96"
          rx="71.44"
          ry="71.63"
        />
      </g>
    </g>
  </svg>
);

export default TrialIcon;
