import React from 'react';

const NodeConnect = ({
  primaryColor = '#0000bf',
  secondaryColor = '#00007f',
}) => {
  return (
    <svg
      id="Calque_CO"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 40.13 40.57"
      width="100%"
      height="100%"
    >
      <defs>
        <style>
          {`
            .cls-1-CO {
              fill: ${primaryColor};

            }
            .cls-2-CO {
              fill: ${secondaryColor};
            }
          `}
        </style>
        <filter
          id="drop-shadow-1"
          x="7.13"
          y="7.57"
          width="33"
          height="33"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dx="2" dy="2" />
          <feGaussianBlur result="blur" stdDeviation="2" />
          <feFlood floodColor="#515151" floodOpacity="0.3" />
          <feComposite in2="blur" operator="in" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Calque_1-CO">
        <path
          className="cls-2-CO"
          d="M10.07,0C4.45-.13-.13,4.45,0,10.07c.12,5.29,4.74,9.61,10.03,9.61h8.16c.82,0,1.48-.66,1.48-1.48v-8.16c0-5.29-4.32-9.91-9.61-10.03Z"
        />
        <path
          className="cls-1-CO"
          d="M21.2,31.62c5.61.13,10.19-4.45,10.06-10.06-.12-5.29-4.74-9.61-10.03-9.61h-8.18c-.81,0-1.46.66-1.46,1.46v8.18c0,5.29,4.32,9.91,9.61,10.03Z"
        />
      </g>
    </svg>
  );
};

export default NodeConnect;
