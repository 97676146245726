const MainCardItems = ({
  backgroundColor = '#d3d3d3',
  secondaryColor = '#efefef',
  color = '#e21e1e',
  highlightColor = '#fff',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 187.5 120.2"
    id="Calque_2"
  >
    <g id="Calque_1-2">
      <rect
        fill={backgroundColor}
        width="179.42"
        height="54.72"
        rx="8.55"
        ry="8.55"
      />
      <rect
        fill={color}
        x="12.84"
        y="9.3"
        width="79.95"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        fill={secondaryColor}
        x="12.7"
        y="37.13"
        width="154.8"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        fill={color}
        x="95.28"
        y="9.42"
        width="36.4"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <path
        fill={backgroundColor}
        d="M172.9,0h8.81c3.2,0,5.79,2.6,5.79,5.79v24.06c0,.85-.69,1.55-1.55,1.55h-13.05c-.85,0-1.55-.69-1.55-1.55V1.55c0-.85.69-1.55,1.55-1.55Z"
      />
      <rect
        fill={secondaryColor}
        x="134.61"
        y="9.47"
        width="32.78"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        fill={secondaryColor}
        x="12.59"
        y="21.54"
        width="154.8"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        fill={highlightColor}
        x="180.38"
        y="4.01"
        width="4.7"
        height="4.7"
        rx="1.05"
        ry="1.05"
      />
      <rect
        fill={highlightColor}
        x="180.38"
        y="10.56"
        width="4.7"
        height="4.7"
        rx="1.05"
        ry="1.05"
      />
      <rect
        fill={highlightColor}
        x="180.38"
        y="16.84"
        width="4.7"
        height="4.7"
        rx="1.05"
        ry="1.05"
      />
      <rect
        fill={highlightColor}
        x="180.38"
        y="24.01"
        width="4.7"
        height="4.7"
        rx="1.05"
        ry="1.05"
      />
      <rect
        fill={backgroundColor}
        y="65.48"
        width="179.42"
        height="54.72"
        rx="8.55"
        ry="8.55"
      />
      <rect
        fill={secondaryColor}
        x="12.84"
        y="74.78"
        width="79.95"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        fill={secondaryColor}
        x="12.7"
        y="102.61"
        width="154.8"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        fill={secondaryColor}
        x="95.28"
        y="74.89"
        width="36.4"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <path
        fill={backgroundColor}
        d="M172.9,65.48h8.81c3.2,0,5.79,2.6,5.79,5.79v24.06c0,.85-.69,1.55-1.55,1.55h-13.05c-.85,0-1.55-.69-1.55-1.55v-28.3c0-.85.69-1.55,1.55-1.55Z"
      />
      <rect
        fill={secondaryColor}
        x="134.61"
        y="74.95"
        width="32.78"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        fill={secondaryColor}
        x="12.59"
        y="87.02"
        width="154.8"
        height="8.87"
        rx="4.44"
        ry="4.44"
      />
      <rect
        fill={highlightColor}
        x="180.38"
        y="69.48"
        width="4.7"
        height="4.7"
        rx="1.05"
        ry="1.05"
      />
      <rect
        fill={highlightColor}
        x="180.38"
        y="76.04"
        width="4.7"
        height="4.7"
        rx="1.05"
        ry="1.05"
      />
      <rect
        fill={highlightColor}
        x="180.38"
        y="82.32"
        width="4.7"
        height="4.7"
        rx="1.05"
        ry="1.05"
      />
      <rect
        fill={highlightColor}
        x="180.38"
        y="89.49"
        width="4.7"
        height="4.7"
        rx="1.05"
        ry="1.05"
      />
    </g>
  </svg>
);

export default MainCardItems;
