import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  useParams,
  useLocation,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

//components
import MainLayoutV2 from '../../layouts/MainLayoutV2';
import Blocks from '../../stories/layout-components/Block';
import nodeAxiosFirebase from '../../utils/nodeAxiosFirebase';
import { List, ListItem, ListItemText } from '@mui/material';
import GeneralText from '../../stories/general-components/GeneralText';

const Reports = () => {
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const startDate =
    searchParams.get('start') || localStorage.getItem('insightsstart');
  const endDate =
    searchParams.get('end') || localStorage.getItem('insightsend');
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [items, setItems] = useState([]);
  const [totals, setTotals] = useState([]);

  const businessPreference = useSelector((state) => state.core.businessData);
  const cateogires = businessPreference?.categories;

  const getDataFees = async () => {
    try {
      const data = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `insights/fees`,
        body: {
          startDate: moment.unix(startDate).toISOString(),
          endDate: moment.unix(endDate).toISOString(),
        },
      });
      setTotals(data?.totalsByCategory);
      setItems(data?.items);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      getDataFees();
    }
  }, [startDate, endDate]);

  const handleDateSelected = (newDateRange, field) => {
    let start = newDateRange[0];
    let end = newDateRange[1];

    if (start) {
      start = start.startOf('day').unix();
    }
    if (end) {
      end = end.endOf('day').unix();
    }

    // Ensure to update the state properly here
    setSearchParams((prevParams) => {
      prevParams.set('start', start);
      prevParams.set('end', end);
      return prevParams;
    });

    localStorage.setItem('insightsstart', start);
    localStorage.setItem('insightsend', end);
  };

  return (
    <MainLayoutV2
      icon="DashboardOutlined"
      pageTitle={t('fees')}
      subTitle={t('report')}
      actions={{
        onChangeDateRange: {
          action: handleDateSelected,
          dateField: 'billingDate',
          rangeDates: 'multi',
        },
      }}
    >
      <Blocks heightPercentage={98}>
        <div className="row">
          <div className="col-3">
            <GeneralText
              text={t('totals')}
              primary={true}
              fontSize={'14px'}
              size="bold"
              classNameComponent={'px-3'}
            />
            <List>
              {totals &&
                Object.entries(totals)?.map(([key, value]) => (
                  <ListItem dense key={key}>
                    <ListItemText sx={{ width: '70%' }}>
                      {cateogires?.find((c) => c.id === key)?.name ||
                        t('noName')}
                    </ListItemText>
                    <ListItemText sx={{ width: '30%' }}>
                      {' '}
                      {((value || 0) / 10000)?.toFixed(2) + ' $'}
                    </ListItemText>
                  </ListItem>
                ))}
            </List>
          </div>
          <div className="col-9">
            <GeneralText
              text={t('fees')}
              primary={true}
              fontSize={'14px'}
              size="bold"
              classNameComponent={'px-3'}
            />
            <List>
              {items?.length > 0 &&
                items?.map((item, index) => (
                  <ListItem divider dense key={index}>
                    <ListItemText sx={{ width: '35%' }}>
                      {item?.name}
                    </ListItemText>
                    <ListItemText sx={{ width: '25%' }}>
                      {cateogires?.find((c) => c.id === item?.categoryId)
                        ?.name || t('noName')}
                    </ListItemText>
                    <ListItemText sx={{ width: '30%' }}>
                      {item?.targetName}
                    </ListItemText>
                    <ListItemText sx={{ width: '20%' }}>
                      {((item?.finances?.fees || 0) / 10000)?.toFixed(2) + ' $'}
                    </ListItemText>
                  </ListItem>
                ))}
            </List>
          </div>
        </div>
      </Blocks>
    </MainLayoutV2>
  );
};

export default Reports;
