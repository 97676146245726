import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import * as Icons from '@mui/icons-material';
import { motion } from 'framer-motion';
import ModalLarge from './Base/ModalLarge';
import Select from '../stories/general-components/Select';
import GeneralText from '../stories/general-components/GeneralText';
import { ArrowForwardOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { setNodeAssistant } from '../redux/actions-v2/coreAction';

const ModalConvert = (props) => {
  const { t } = useTranslation();
  const {
    title,
    handleDuplicate,
    handleTransform,
    structure,
    elementData,
    isOpen,
  } = props;

  const dispatch = useDispatch();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const [matchedFields, setMatchedFields] = useState([]);
  const [selectedStructure, setSelectedStructure] = useState(null);
  const [elementDataTransform, setElementDataTransform] = useState({});
  const [adjustedStructureFields, setAdjustedStructureFields] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Number of structures visible at once
  const visibleCount = 5;

  // Navigate to previous group
  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, businessStructures.length - visibleCount)
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleFieldMatch = (currentField, newField) => {
    setMatchedFields((prev) => {
      const updatedFields = [...prev];

      // Remove the existing mapping for the newField if it exists
      const indexToRemove = updatedFields.findIndex(
        (f) => f.newField === newField
      );
      if (indexToRemove >= 0) {
        updatedFields.splice(indexToRemove, 1); // Remove the existing mapping
      }

      // Find the index of the currentField in the matchedFields list
      const existingIndex = updatedFields.findIndex(
        (f) => f.currentField === currentField
      );

      if (existingIndex >= 0) {
        // Update the existing match
        updatedFields[existingIndex] = { currentField, newField };
      } else {
        // Add a new match
        updatedFields.push({ currentField, newField });
      }

      return updatedFields;
    });
  };

  const handleSubmitTransform = () => {
    handleTransform(selectedStructure, matchedFields);
  };

  const handleSubmitDuplicate = () => {
    handleDuplicate(selectedStructure, matchedFields);
  };

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  const businessStructures = businessStructure?.structures;
  const newStructure = businessStructures?.find(
    (s) => s.id === selectedStructure
  );

  const structureFields = structure?.fields || [];
  const newStructureFields = newStructure?.fields || [];

  useEffect(() => {
    //for each value find the typeData with the structureFields by matching field?.value and the key
    const newElementDataTransformed = {};
    Object.entries(elementData).forEach(([key, value]) => {
      //for each field in the new structure, check if it matches the key
      structureFields.forEach((field) => {
        if (field.value === key) {
          if (field?.typeData === 'date' || field?.typeData === 'data-time') {
            newElementDataTransformed[field.value] = moment
              .unix(value?.seconds || value?._seconds)
              .format('DD MMM YYYY');
          } else if (
            field?.typeData === 'search' &&
            field?.value === 'targetId'
          ) {
            newElementDataTransformed[field.value] =
              elementData?.targetDetails?.name;
          } else if (field?.value === 'targetProfileId') {
            newElementDataTransformed[field.value] =
              elementData?.targetProfileDetails?.name;
          } else if (field?.value === 'status') {
            newElementDataTransformed[field.value] = newStructureFields?.find(
              (f) => f.value === 'status'
            )?.selections?.[0]?.label_fr;
          } else if (!value) {
            newElementDataTransformed[field.value] = '';
          } else {
            newElementDataTransformed[field.value] = value;
          }
        }
      });
    });
    if (newStructure?.collectionField === 'cardsinvoiced') {
      newElementDataTransformed['invoiceDate'] = moment().format('DD MMM YYYY');
      newElementDataTransformed['dueDate'] = moment().format('DD MMM YYYY');
    }
    setElementDataTransform(newElementDataTransformed);
  }, [elementData, newStructureFields, structureFields]);

  useEffect(() => {
    const adjustedFields = [];
    newStructureFields.forEach((field) => {
      if (
        elementData[field.value] !== null &&
        elementData[field.value] !== ''
      ) {
        adjustedFields.push(field);
      }
    });
    setAdjustedStructureFields(adjustedFields);
  }, [selectedStructure]);

  useEffect(() => {
    if (selectedStructure) {
      dispatch(
        setNodeAssistant({
          text: t('msgElementConvert'),
          color: null,
        })
      );
    } else {
      dispatch(
        setNodeAssistant({
          text: t('msgSelectStructure'),
          color: null,
        })
      );
    }
  }, [selectedStructure, matchedFields]);

  return (
    <div>
      <ModalLarge
        title={title}
        subTitle={t('convertAndApply')}
        isOpen={isOpen}
        {...props}
        labels={{
          primary: t('convert'),
          secondary: t('duplicateConvert'),
        }}
        handlePrim={handleSubmitTransform}
        disabledPrim={!selectedStructure}
        handleSec={handleSubmitDuplicate}
      >
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={handlePrev} disabled={currentIndex === 0}>
              <Icons.ArrowCircleLeftOutlined
                htmlColor={'#696969'}
                style={{ fontSize: '25px' }}
              />
            </IconButton>
            <motion.div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100px',
                overflow: 'hidden',
                position: 'relative',
              }}
              animate={{
                x: 0,
              }}
              transition={{
                duration: 0.5,
                ease: 'easeInOut',
              }}
            >
              {businessStructures
                ?.slice(currentIndex, currentIndex + visibleCount)
                .map((structure, index) => {
                  const IconComponent = Icons[structure?.icon];
                  return (
                    <motion.div
                      key={structure.id}
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setSelectedStructure(structure?.id)}
                      style={{
                        cursor: 'pointer',
                        textAlign: 'center',
                        width: '100px',
                        border:
                          selectedStructure === structure?.id
                            ? isDarkMode
                              ? '1px solid #fff'
                              : '1px solid #000'
                            : 'none',
                        padding: '10px',
                        borderRadius: '10px',
                      }}
                    >
                      {IconComponent && (
                        <IconComponent style={{ fontSize: '30px' }} />
                      )}
                      <GeneralText
                        text={structure.name}
                        primary={true}
                        size="medium"
                        fontSize="11px"
                      />
                    </motion.div>
                  );
                })}
            </motion.div>
            <IconButton
              onClick={handleNext}
              disabled={currentIndex >= businessStructures.length - 1}
            >
              <Icons.ArrowCircleRightOutlined
                htmlColor={'#696969'}
                style={{ fontSize: '25px' }}
              />
            </IconButton>
          </div>
          {selectedStructure && (
            <div className="row mb-4 mt-3">
              <div className="col-4">
                <h4>{t('structureFields')}</h4>
                {elementDataTransform &&
                  adjustedStructureFields
                    ?.filter((f) => f?.type === 'default')
                    ?.map((field) => (
                      <div
                        key={field?.value}
                        className="row middle-content"
                        style={{ marginTop: '43px' }}
                      >
                        <div className="col-10">
                          <GeneralText
                            text={field?.name}
                            primary={true}
                            size={'medium'}
                            fontSize={'13px'}
                            key={field?.value}
                            value={
                              matchedFields?.find(
                                (f) => f.currentField === field?.value
                              )?.newField ?? field?.value
                            }
                          />{' '}
                        </div>
                        <div className="col-2">
                          <ArrowForwardOutlined htmlColor="#69696970" />
                        </div>
                      </div>
                    ))}
              </div>
              <div className="col-4 px-3">
                <h4>{t('fieldsToMap')}</h4>
                {elementDataTransform &&
                  adjustedStructureFields
                    ?.filter((f) => f?.type === 'default')
                    ?.map((field) => (
                      <div
                        key={field?.value + 'select-div'}
                        className="row middle-content"
                      >
                        <div className="col-10">
                          <Select
                            disabled={true}
                            key={field?.value + 'select'}
                            value={
                              matchedFields?.find(
                                (f) => f.currentField === field?.value
                              )?.newField ??
                              field?.value ??
                              null
                            }
                            selections={[
                              ...structureFields?.map((nf) => ({
                                label: nf?.name,
                                value: nf?.value,
                                id: nf?.value,
                              })),
                            ]}
                            fullWidth
                            onChange={(e, value) =>
                              handleFieldMatch(value, field?.value)
                            }
                          />
                        </div>
                        <div className="col-2">
                          <ArrowForwardOutlined
                            htmlColor="#69696970"
                            sx={{ mt: 1 }}
                          />
                        </div>
                      </div>
                    ))}
              </div>
              <div className="col-4 px-3">
                <h4>{t('newElement')}</h4>
                {elementDataTransform &&
                  adjustedStructureFields
                    ?.filter((f) => f?.type === 'default')
                    ?.map((field) => (
                      <div key={field?.value} className="">
                        <div className="col-12" style={{ marginTop: '49.3px' }}>
                          <GeneralText
                            text={
                              matchedFields?.find(
                                (f) => f.newField === field?.value
                              )?.currentField
                                ? elementDataTransform[
                                    matchedFields?.find(
                                      (f) => f.newField === field?.value
                                    )?.currentField
                                  ]
                                : elementDataTransform[field?.value] ?? '-'
                            }
                            primary={true}
                            size={'medium'}
                            fontSize={'13px'}
                            key={field?.value + 'key'}
                          />
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          )}
          {selectedStructure && (
            <div className="row mb-4">
              <div className="col-4">
                {elementDataTransform &&
                  adjustedStructureFields
                    ?.filter((f) => f?.type === 'data')
                    ?.map((field) => (
                      <div
                        key={field?.value}
                        className="row middle-content"
                        style={{ marginTop: '43px' }}
                      >
                        <div className="col-10">
                          <GeneralText
                            text={field?.name}
                            primary={true}
                            size={'medium'}
                            fontSize={'13px'}
                            key={field?.value}
                            value={
                              matchedFields?.find(
                                (f) => f.currentField === field?.value
                              )?.newField ?? field?.value
                            }
                          />{' '}
                        </div>
                        <div className="col-2">
                          <ArrowForwardOutlined htmlColor="#69696970" />
                        </div>
                      </div>
                    ))}
              </div>
              <div className="col-4 px-3" style={{ marginTop: '20px' }}>
                {elementDataTransform &&
                  adjustedStructureFields
                    ?.filter((f) => f?.type === 'data')
                    ?.map((field) => (
                      <div
                        key={field?.value + 'select-div'}
                        className="row middle-content"
                      >
                        <div className="col-10">
                          <Select
                            disabled={field?.type === 'default'}
                            key={field?.value + 'select'}
                            value={
                              matchedFields?.find(
                                (f) => f.currentField === field?.value
                              )?.newField ??
                              field?.value ??
                              null
                            }
                            selections={[
                              ...structureFields
                                ?.filter((f) => f?.type === 'data')
                                ?.map((nf) => ({
                                  label: nf?.name,
                                  value: nf?.value,
                                  id: nf?.value,
                                })),
                            ]}
                            fullWidth
                            onChange={(e, value) =>
                              handleFieldMatch(value, field?.value)
                            }
                          />
                        </div>
                        <div className="col-2">
                          <ArrowForwardOutlined
                            htmlColor="#69696970"
                            sx={{ mt: 1 }}
                          />
                        </div>
                      </div>
                    ))}
              </div>
              <div className="col-4 px-3">
                {elementDataTransform &&
                  adjustedStructureFields
                    ?.filter((f) => f?.type === 'data')
                    ?.map((field) => (
                      <div key={field?.value} className="">
                        <div className="col-12" style={{ marginTop: '49.3px' }}>
                          <GeneralText
                            text={
                              matchedFields?.find(
                                (f) => f.newField === field?.value
                              )?.currentField
                                ? elementDataTransform[
                                    matchedFields?.find(
                                      (f) => f.newField === field?.value
                                    )?.currentField
                                  ]
                                : elementDataTransform[field?.value] ??
                                  t('noDataConvert')
                            }
                            primary={true}
                            size={'medium'}
                            fontSize={'13px'}
                            key={field?.value + 'key'}
                          />
                        </div>
                      </div>
                    ))}
              </div>
            </div>
          )}
        </div>
      </ModalLarge>
    </div>
  );
};

export default ModalConvert;
