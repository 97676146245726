import React, { useState, useEffect, useRef } from 'react';
import ButtonMUI from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import chroma from 'chroma-js';
import * as Icons from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';

export const Button = ({
  size,
  label,
  onClick,
  type,
  delay,
  disabled,
  endIcon,
  onMouseDown,
  buttonSx,
  variant,
  startIcon,
  disableRipple,
  color,
  noShadow,
  success,
  fullWidth,
}) => {
  const [loading, setLoading] = useState(false);
  const timer = useRef();
  const theme = useTheme();
  const IconComponent = Icons[endIcon];
  const IconComponentStart = Icons[startIcon];
  const businessPreference = useSelector((state) => state.core.businessData);
  const isDarkMode = theme.palette.mode === 'dark';

  const handleClick = async (e) => {
    if (!loading) {
      setLoading(true);
      await onClick(e);
    }
  };

  const darkColor = () => {
    let finalColor = businessPreference?.secColor || '#000000';

    return chroma(finalColor).darken(0.9).hex();
  };

  useEffect(() => {
    if (loading) {
      if (success) {
        setLoading(false);
      } else {
        timer.current = setTimeout(() => {
          setLoading(false);
        }, delay || 1000);
      }
    }
    return () => {
      clearTimeout(timer.current);
    };
  }, [loading, success]);

  return (
    <ButtonMUI
      fullWidth={fullWidth}
      disableElevation
      onMouseDown={onMouseDown}
      variant={variant || 'contained'}
      type={type}
      className="mui-button"
      sx={{
        ...buttonSx,
        height: size === 'small' ? '28px' : '34px',
        background:
          variant === 'contained'
            ? `linear-gradient(145deg, ${
                businessPreference?.secColor || '#FF6F91'
              }, ${darkColor()})`
            : variant === 'text'
            ? isDarkMode
              ? '#1a1a1a'
              : '#FFFFFF'
            : null,
        boxShadow: noShadow
          ? null
          : isDarkMode
          ? `0px 4px 10px rgba(0, 0, 0, 0.2),
        0px -2px 4px rgba(0, 0, 0, 0.3) inset`
          : `0px 4px 10px rgba(0, 0, 0, 0.2),
                          0px -2px 4px rgba(255, 255, 255, 0.3) inset`,
        color:
          variant === 'contained'
            ? '#FFFFFF'
            : isDarkMode
            ? '#FFFFFF'
            : color || null,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          background:
            variant === 'contained'
              ? `linear-gradient(145deg,${darkColor()}, ${
                  businessPreference?.secColor || '#FF6F91'
                })`
              : null,
          boxShadow: noShadow
            ? null
            : isDarkMode
            ? `0px 4px 10px rgba(0, 0, 0, 0.2),
        0px -2px 4px rgba(0, 0, 0, 0.3) inset`
            : `0px 6px 15px rgba(0, 0, 0, 0.3),
                            0px -3px 6px rgba(255, 255, 255, 0.4) inset`,
        },
        '&:active': {
          boxShadow: `0px 3px 8px rgba(0, 0, 0, 0.2),
                            0px -2px 3px rgba(255, 255, 255, 0.3) inset`,
        },
        '&.Mui-disabled': {
          background: isDarkMode
            ? 'rgba(0, 0, 0, 0.5)'
            : 'rgba(255, 255, 255, 0.5)',
          color: '#a1a1a1',
          boxShadow: noShadow
            ? null
            : isDarkMode
            ? `0px 4px 10px rgba(0, 0, 0, 0.2),
        0px -2px 4px rgba(0, 0, 0, 0.3) inset`
            : `0px 4px 10px rgba(0, 0, 0, 0.2),
                          0px -2px 4px rgba(255, 255, 255, 0.3) inset`,
        },
      }}
      disableTouchRipple={disableRipple}
      disableRipple={disableRipple}
      onClick={handleClick}
      size={size || 'medium'}
      disabled={disabled || loading}
      startIcon={IconComponentStart && <IconComponentStart />}
      endIcon={IconComponent && <IconComponent />}
      color={loading ? 'light' : 'secondary'}
    >
      {loading ? <CircularProgress size={24} color="inherit" /> : label}
    </ButtonMUI>
  );
};

export default React.memo(Button);
