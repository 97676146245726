import React, { useEffect } from 'react';

const EmailVisual = ({ selectedTemplate, elementData, customMessage }) => {
  // Function to replace placeholders in the template
  const replacePlaceholders = (template, elementData) => {
    let mergedData = { ...elementData, customBody: customMessage };

    if (!template || !elementData) {
      console.warn('Template or elementData is missing');
      return template || '';
    }

    // Replace placeholders in the format ${data.key}
    return template.replace(/\$\{data\.([^}]+)\}/g, (_, key) => {
      // Safely access nested properties in elementData
      const value = key
        .split('.')
        .reduce((acc, curr) => acc?.[curr], mergedData);
      return value || '';
    });
  };

  const processedHtml = replacePlaceholders(
    selectedTemplate?.html,
    elementData
  );

  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = selectedTemplate?.css;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [selectedTemplate]);

  return <div dangerouslySetInnerHTML={{ __html: processedHtml }} />;
};

export default EmailVisual;
