import React, { useState, useEffect } from 'react';
import MainLayoutV2 from '../layouts/MainLayoutV2';

//Tu peux commenter la version public et décommenter la version app pour voir la différence

//---VERSION PUBLIC---
const JcraquePourToiMonCoco = ({ name, date }) => {
  return (
    <div>
      <div className="row">
        <div className="col-6">{name}</div>
        <div className="col-6">{date}</div>
      </div>
    </div>
  );
};

export default JcraquePourToiMonCoco;
