import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as sidebarActions from '../redux/actions/sidebar-actions';
import LocationSidebar from './LocationSidebar';
import StructureSidebar from './StructureSidebar';

const AllSidebarsRoot = () => {
  const dispatch = useDispatch();

  const globalSidebarState = useSelector((state) => state.sidebarReducer);

  return (
    <React.Fragment>
      {globalSidebarState.locationSidebar.show && (
        <LocationSidebar
          {...globalSidebarState.locationSidebar}
          closeSidebar={() =>
            dispatch(
              sidebarActions.locationSidebar({
                show: false,
              })
            )
          }
        />
      )}
      {globalSidebarState.structureSidebar.show && (
        <StructureSidebar
          {...globalSidebarState.structureSidebar}
          closeSidebar={() =>
            dispatch(
              sidebarActions.structureSidebar({
                show: false,
              })
            )
          }
        />
      )}
    </React.Fragment>
  );
};

export default AllSidebarsRoot;
