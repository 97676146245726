import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import MainLayoutV2 from '../../layouts/MainLayoutV2';
import Marketplace from '../specific/Marketplace';
import Block from '../../stories/layout-components/Block';

const SettingsCollections = () => {
  const { t, i18n } = useTranslation();
  const currentLangCode = i18n.language || 'en';
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const structureId = searchParams.get('structure');

  const [displayMarketplace, setDisplayMarketplace] = useState(false);

  const businessStructure = useSelector(
    (state) => state.core.businessStructure
  );

  useEffect(() => {
    setDisplayMarketplace(false);
    setTimeout(() => {
      setDisplayMarketplace(true);
    }, 1000);
  }, [structureId]);

  const businessStructures = businessStructure?.structures || [];
  const currentStrucure = businessStructures.find((s) => s.id === structureId);
  const currentCollection = currentStrucure?.collectionField;

  return (
    <MainLayoutV2
      pageTitle={t('integrations')}
      subTitle={t('connectSoftwares')}
      icon="CategoryOutlined"
      hideMenu
    >
      <Block height={1} heightPercentage={98} noShadow noBorder>
        {displayMarketplace && (
          <Marketplace
            currentCollection={currentCollection}
            structureId={structureId}
          />
        )}
      </Block>
    </MainLayoutV2>
  );
};

export default SettingsCollections;
