import React, { useState, useEffect } from 'react';
import MainLayoutV2 from '../layouts/MainLayoutV2';
import { useSelector } from 'react-redux';
import JcraquePourToiMonCoco from './JcraquePourToiMonCoco';
import { use } from 'react';

//Tu peux commenter la version public et décommenter la version app pour voir la différence

//---VERSION APP---
const JcraqueInterieurementPourToiMonCoco = () => {
  const businessPreference = useSelector((state) => state.core.businessData);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [lastClick, setLastClick] = useState('');
  const [categories, setCategories] = useState([]);

  const handleCategorySelected = async (category) => {
    try {
      setLastClick(selectedCategory);
      setSelectedCategory(category);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setCategories(businessPreference?.categories);
    setSelectedCategory('');
  }, [businessPreference?.categories]);

  return (
    <MainLayoutV2>
      <div>
        {selectedCategory}
        <div className="row mt-5">
          {categories?.map((category) => (
            <div
              onClick={() => handleCategorySelected(category.name)}
              className="col-12"
              style={{ color: businessPreference?.mainColor }}
            >
              <JcraquePourToiMonCoco name={category.name} date={category.id} />
            </div>
          ))}
        </div>
        {lastClick}
      </div>
    </MainLayoutV2>
  );
};

export default JcraqueInterieurementPourToiMonCoco;
